import { ConnectedWallet } from "@terra-money/wallet-provider";
import { Token, TokenToAssetInfo } from "types";
import { useQuery } from "react-query";
import { QUERY_KEY } from "queries";
import { contractQuery } from "./utils";
import { DEXTER_ROUTER, getContractAddress } from "../utils/getContractAddress";

export const useSwapPriceQuery = (
  bidAmount: string,
  wallet?: ConnectedWallet,
  offerAsset?: Token,
  askAsset?: Token
) => {
  return useQuery(
    [QUERY_KEY.SIMULATE_SWAP_OPERATIONS],
    () => {
      if (!wallet || !offerAsset || !askAsset) {
        return "";
      }
      const swapQuery = {
        simulate_swap_operations: {
          offer_amount: bidAmount,
          operations: [
            {
              swap: {
                offer_asset_info: TokenToAssetInfo(offerAsset),
                ask_asset_info: TokenToAssetInfo(askAsset),
              },
            },
          ],
        },
      };
      const routerAddr = getContractAddress(
        wallet.network.chainID,
        DEXTER_ROUTER
      );

      return contractQuery<any, { amount: string }>(
        wallet.network,
        routerAddr,
        swapQuery
      ).then((d) => d.amount);
    },
    {
      enabled: false,
      initialData: "",
    }
  );
};
