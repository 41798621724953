import { useState, useEffect } from "react";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { CreateTxOptions } from "@terra-money/terra.js";
import {
  Box,
  Button,
  Typography
} from "@mui/material";
import { isEmpty, isUndefined } from "lodash";
import Big from "big.js";

import { useTx, UseTxProps } from "libs/transactions";
import { BuildSwapMsg } from "../../../transactions/swapTransaction";
import {
  useFetchTokenBalance,
  useSwapPriceQuery as useSwapAmountQuery,
  useEstimateFeeQuery
} from "queries";
import { beautifyAmountByToken } from "utils/amountFormatter";
import { LUNA, Token } from "types";
import {
  WalletGuard,
  CustomizedTooltip,
  ClickableIcon,
  SlippageConfigModal,
  TokenSelectorForm,
  Card,
  TextSkeleton
} from "components";
import { ReactComponent as FlipIcon } from '../../../assets/icons/icon-flip.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/icon-settings.svg';
import { ReactComponent as TooltipIcon } from '../../../assets/icons/icon-tooltip.svg';

export const SwapForm = () => {
  // Wallet
  const connectedWallet = useConnectedWallet();
  const walletAddress = connectedWallet?.walletAddress;

  // States for modals
  const [openSlippageConfigModal, setOpenSlippageConfigModal] =
    useState<boolean>(false);
  const [slippage, setSlippage] = useState<string>("0.1");

  // States for the Swap form
  const [selectedAssetFrom, setSelectedAssetFrom] = useState<Token | undefined>(
    LUNA
  );
  // TODO: Ideally this shouldn't have the option to be undefined, replace it with the DEX token when available later as the default value
  const [selectedAssetTo, setSelectedAssetTo] = useState<Token | undefined>();
  const [selectedAssetFromAmount, setSelectedAssetFromAmount] =
    useState<string>("");
  const [selectedAssetToAmount, setSelectedAssetToAmount] =
    useState<string>("");
  const [exchangeRate, setExchangeRate] = useState<string>("");
  const [minimumReceived, setMinimumReceived] = useState<string>("");
  const swapInputsAreValid =
    selectedAssetFrom &&
    selectedAssetTo &&
    selectedAssetFromAmount &&
    selectedAssetToAmount;

  // Fetch balance for selected From token to automatically populate the input form
  const formattedBalance = useFetchTokenBalance(
    walletAddress as string,
    selectedAssetFrom!
  );
  const insufficientBalance =
    selectedAssetFromAmount && formattedBalance && 
    parseFloat(selectedAssetFromAmount) > parseFloat(formattedBalance) || 
    false;

  // Fetch exchange rate between two tokens
  const {
    data: selectedAssetToAmountCalculated,
    refetch: refetchSwapAmount
  } = useSwapAmountQuery(
    selectedAssetFromAmount,
    connectedWallet,
    selectedAssetFrom,
    selectedAssetTo
  );
  const [isLoadingSwapPrice, setIsLoadingSwapPrice] = useState(false);

  // Fetch gas fee
  const [potentialTransaction, setPotentialTransaction] = useState(
    {} as CreateTxOptions
  );
  const {
    isLoading: isLoadingEstimatedFee,
    data: estimatedFee
  } = useEstimateFeeQuery(connectedWallet, potentialTransaction);

  // Execute the swap
  const [swapTxResult, swapTx] = useTx<UseTxProps>(
    (props: UseTxProps) => {
      return {
        msgs: props.msgs,
      };
    },
    { txKey: "EXECUTE:SWAP" },
    {}
  );

  // Set balance of selected token
  useEffect(() => {
    setSelectedAssetFromAmount(formattedBalance as string);
  }, [formattedBalance, selectedAssetFrom]);

  // Refetch exchange rate
  useEffect(() => {
    if (!isEmpty(selectedAssetFromAmount) && !isUndefined(selectedAssetTo)) {
      setIsLoadingSwapPrice(true);
      // Set 2s delay for:
      // - Let a query to finish before starting a new one
      // - UI will display the correct latest data (query overlapping causing wrong data to be displayed)
      // - Give time for user to finish inputting the amount before we start querying
      setTimeout(() => {
        refetchSwapAmount();
        setIsLoadingSwapPrice(false);
        setPotentialTransaction(
          BuildSwapMsg(
            connectedWallet!,
            selectedAssetFrom!,
            "" + Number(selectedAssetFromAmount) * 1000000,
            selectedAssetTo!,
            slippage
          )
        );
      }, 2000);
    }
  }, [
    selectedAssetFromAmount,
    selectedAssetFrom,
    selectedAssetTo,
    refetchSwapAmount,
  ]);

  // Set and calculate exchange rate and minimum received
  useEffect(() => {
    if (isEmpty(selectedAssetFromAmount) || isUndefined(selectedAssetTo)) {
      setSelectedAssetToAmount("");
    } else {
      setSelectedAssetToAmount(selectedAssetToAmountCalculated ?? "");

      if (selectedAssetToAmountCalculated) {
        // TODO: Beautify the amount

        // Calculate exchange rate
        const formattedSelectedAssetFromAmount = parseFloat(
          selectedAssetFromAmount
        );
        const formattedAssetToAmountCalculated = Big(
          selectedAssetToAmountCalculated
        ).toNumber();
        const calculatedExchangeRate =
          formattedSelectedAssetFromAmount / formattedAssetToAmountCalculated;
        setExchangeRate(calculatedExchangeRate.toFixed(2).toString());

        // Calculate minimum received
        const formattedSlippage = parseFloat(slippage);
        const minimumReceivedCalculated =
          formattedAssetToAmountCalculated -
          formattedAssetToAmountCalculated * (formattedSlippage / 100);
        setMinimumReceived(minimumReceivedCalculated.toString());
      }
    }
  }, [
    selectedAssetToAmountCalculated,
    selectedAssetFromAmount,
    selectedAssetTo,
    slippage,
  ]);

  const onFlipAsset = () => {
    const tempSelectedAssetFrom = selectedAssetFrom;
    setSelectedAssetFrom(selectedAssetTo);
    setSelectedAssetTo(tempSelectedAssetFrom);

    setSelectedAssetFromAmount(selectedAssetToAmount ?? "");
  };

  const onPerformSwap = () => {
    if (
      selectedAssetFrom &&
      selectedAssetTo &&
      potentialTransaction &&
      estimatedFee
    ) {
      swapTx({
        msgs: potentialTransaction.msgs,
        wallet: connectedWallet!,
        fee: estimatedFee,
      });
    }
  };

  const slippageConfigModal = (
    <SlippageConfigModal
      open={openSlippageConfigModal}
      onClose={() => setOpenSlippageConfigModal(false)}
      slippageValue={slippage}
      setSlippage={setSlippage}
    />
  );

  const exchangeRateToDisplay = isLoadingSwapPrice
    ? <TextSkeleton />
    : (
      <Typography>
        {`1 ${selectedAssetFrom?.symbol} = ${exchangeRate} ${selectedAssetTo?.symbol}`}
      </Typography>
    );
  const exchangeRateSection = (
    <Box display={'flex'} justifyContent={"space-between"} alignItems='center' marginY={1}>
      <Typography className='label'>Rate</Typography>
      {exchangeRateToDisplay}
    </Box>
  );
  
  const slippageSection = (
    <Box display={'flex'} justifyContent={"space-between"} alignItems='center' marginY={1}>
      <Box display={"flex"} alignItems="center">
        <Typography className='label'>Slippage</Typography>
        <ClickableIcon
          variant="button"
          icon={<SettingsIcon />}
          onClick={() => setOpenSlippageConfigModal(true)}
          className="button-icon-base button-icon"
        />
        <CustomizedTooltip
          triggerElement={<TooltipIcon />}
          text="Yo this is a tooltip!"
        />
      </Box>
      <Typography>{slippage} %</Typography>
    </Box>
  );

  const minimumReceivedToDisplay = isLoadingSwapPrice
    ? <TextSkeleton />
    : (
      <Typography>{`${minimumReceived} ${selectedAssetTo?.symbol}`}</Typography>
    );
  const minimumReceivedSection = (
    <Box display={'flex'} justifyContent={"space-between"} alignItems='center' marginY={1}>
      <Box display={"flex"} alignItems="center">
        <Typography className='label' mr={1}>Minimum Received</Typography>
        <CustomizedTooltip
          triggerElement={<TooltipIcon />}
          text="Yo this is a tooltip!"
        />
      </Box>
      {minimumReceivedToDisplay}
    </Box>
  );

  const swapAdditionalDetailsContent = (
    <Box mt='32px'>
      {exchangeRateSection}
      {slippageSection}
      {minimumReceivedSection}
    </Box>
  );

  // const swapAdditionalDetails = swapInputsAreValid && swapAdditionalDetailsContent;
  const swapAdditionalDetails = swapAdditionalDetailsContent;

  const fromAssetSection = (
    <TokenSelectorForm
      token={selectedAssetFrom}
      excludedToken={selectedAssetTo}
      onSelectToken={setSelectedAssetFrom}
      amount={selectedAssetFromAmount}
      onChangeAmount={(amount: string) => setSelectedAssetFromAmount(amount)}
      label='From'
      withAmountPreset
    />
  );

  const toAssetSection = (
    <TokenSelectorForm
      token={selectedAssetTo}
      excludedToken={selectedAssetFrom}
      onSelectToken={setSelectedAssetTo}
      amount={selectedAssetToAmount}
      disableInput
      isLoading={isLoadingSwapPrice}
      label='To (estimated)'
      withAmountPreset={false}
    />
  );

  const flipButton = (
    <Box my={'16px'}>
      <Button
        variant="contained"
        className="button-base button-circle blue"
        onClick={onFlipAsset}
      >
        <FlipIcon />
      </Button>
    </Box>
  );

  const swapButton = (
    <Button
      variant="contained"
      className="button-base button-primary gradient"
      disabled={
        !swapInputsAreValid || 
        isLoadingSwapPrice || 
        insufficientBalance
      }
      fullWidth
      onClick={onPerformSwap}
    >
      <Typography variant="button">Swap</Typography>
    </Button>
  );

  const swapFormButtonToDisplay = <WalletGuard element={swapButton} />

  const rawGasFeeData = estimatedFee?.amount.toData();
  const formattedGasFee = 
    rawGasFeeData 
    ? beautifyAmountByToken(rawGasFeeData[0].amount, LUNA) + ' LUNA'
    : 'N/A';
  const gasFeeToDisplay = isLoadingEstimatedFee
    ? <TextSkeleton />
    : <Typography>{formattedGasFee}</Typography>;
  const gasFeeSection = (
    <Box
      mt={'8px'}
      display='flex'
      justifyContent={'center'}
      alignItems='center'
    >
      <Typography className="label" mr={'8px'}>Gas Fee:</Typography>
      {gasFeeToDisplay}
    </Box>
  );

  const formButton = (
    <Box mt='32px'>
      {swapFormButtonToDisplay}
      {gasFeeSection}
    </Box>
  );

  const swapForm = (
    <Card>
      {fromAssetSection}
      {flipButton}
      {toAssetSection}
      {swapAdditionalDetails}
      {formButton}
      {slippageConfigModal}
    </Card>
  );

  return swapForm;
};
