import { useQuery, UseQueryResult } from 'react-query';
import { useConnectedWallet } from '@terra-money/wallet-provider';
import { QUERY_KEY } from './queryKey';
import { IBCTokensResponse } from 'types';
import { fixTokenResponse } from './utils';

interface IBCTokensNetworkResponse {
  [network: string]: IBCTokensResponse;
}

const fetchIBCTokens = async (network: string): Promise<IBCTokensResponse> => {
  const response = await fetch('https://assets.terra.money/ibc/tokens.json');

  const tokens: IBCTokensNetworkResponse = await response.json();

  return tokens && tokens[network]
    ? fixTokenResponse('ibc', tokens[network], (key) => `ibc/${key}`)
    : {};
};

export const useIBCTokensQuery = (
  preferredNetwork?: string
): UseQueryResult<IBCTokensResponse> => {
  const connectedWallet = useConnectedWallet();

  return useQuery(
    [QUERY_KEY.IBC_TOKENS, preferredNetwork ?? connectedWallet?.network.name],
    ({ queryKey }) => {
      if (!queryKey[1]) {
        return undefined;
      }

      return fetchIBCTokens(queryKey[1]);
    },
    {
      refetchOnMount: false,
    }
  );
};
