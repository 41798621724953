import { Box, Typography } from "@mui/material";

import { EpochStats } from "pages/vote/EpochStats/EpochStats";
import { BribeList } from "./BribeList/BribeList";

export const Bribe = () => {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
      width="100%"
    >
      <Typography variant="h1" mb={"48px"}>
        Bribes
      </Typography>
      {/* TODO: See if we need to create a separate EpochStats component for this page */}
      <EpochStats />
      <BribeList />
    </Box>
  );
};
