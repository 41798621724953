import { contractQuery } from "./utils";
import { ConnectedWallet } from "@terra-money/wallet-provider";
import { useQuery } from "react-query";
import { QUERY_KEY } from "queries";
import {
    DEXTER_GAUGE_CONTROLLER,
    getContractAddress,
} from "utils/getContractAddress";

export const useGaugeVotesQuery = (wallet?: ConnectedWallet) => {
    return useQuery(
        [QUERY_KEY.GAUGE_VOTES, wallet],
        async () => {
            const defaultValue = {} as { [key: string]: string };
            if (!wallet) {
                console.log("wallet not found");
                return Promise.resolve(defaultValue);
            }
            const factoryContract = getContractAddress(
                wallet.network.chainID,
                DEXTER_GAUGE_CONTROLLER
            );
            const res = await contractQuery<
                {
                    user_votes: {
                        user: string;
                        epoch: null;
                    };
                },
                {
                    votes: { [key: string]: string };
                }
            >(wallet.network, factoryContract, {
                user_votes: {
                    user: wallet.walletAddress,
                    epoch: null,
                },
            });
            return res.votes;
        },
        {
            retry: false,
        }
    );
};
