import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { NetworkInfo, useWallet } from '@terra-money/wallet-provider';
import { QUERY_KEY } from './queryKey';
import { LCDClient } from '@terra-money/terra.js/dist/client';
import { u } from '../types/types';
import Big from 'big.js';

export const fetchNativeBalance = async (
  lcdUrl: string,
  chainId: string,
  denom: string,
  walletAddr: string
): Promise<u<Big>> => {
  const lcd = new LCDClient({
    URL: lcdUrl,
    chainID: chainId,
  });

  const [coins] = await lcd.bank.balance(walletAddr);

  const coin = coins.get(denom);

  return Big(coin === undefined ? 0 : coin.amount.toNumber()) as u<Big>;
};

export const useNativeBalanceQuery = (
  walletAddr: string,
  denom: string,
  options: Partial<Pick<UseQueryOptions, 'enabled'>> = { enabled: true }
): UseQueryResult<u<Big>> => {
  const { network } = useWallet();

  return useQuery(
    [QUERY_KEY.LUNA_BALANCE, network, walletAddr, denom],
    ({ queryKey }) => {
      return fetchNativeBalance(network.lcd, network.chainID, denom, walletAddr);
    },
    {
      refetchOnMount: false,
      ...options,
    }
  );
};
