import { useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import classNames from "classnames";

import {
    generatePoolCompositionCell,
    generateBribesAndFeesCell,
} from "utils/tableCellDataGenerator";
import { Token } from "types";
import { Pool } from "queries";
import { CustomizedTooltip } from "components";
import { beautifyDecimalAmount } from "utils/amountFormatter";
import { useSearchPool } from "hooks";

export const GenerateGaugeListTableColumns = (
    onOpenVoteModal: (selectedPoolData: any) => void,
    sufficientVotingPower: boolean
) => {
    const generateShareCell = ({
        current,
        next,
    }: {
        current: number;
        next: number;
    }) => {
        const shareDifference = next - current;
        const shareIsIncreasing = shareDifference > 0;
        const shareNoDifference = shareDifference === 0;
        const differenceSymbol =
            shareIsIncreasing || shareNoDifference ? "+" : "";

        const textColorBasedOnShareDifference = classNames({
            "green-text": shareIsIncreasing,
            "red-text": !shareIsIncreasing && !shareNoDifference,
        });

        const cellText = (
            <Typography
                variant="body2"
                className={textColorBasedOnShareDifference}
                sx={{ borderBottom: "1px dotted" }}
            >
                {next} %
            </Typography>
        );

        const shareDifferenceToDisplay = (
            <Typography
                variant="body3"
                className={textColorBasedOnShareDifference}
            >
                {` (${differenceSymbol}${shareDifference} %)`}
            </Typography>
        );

        const shareBreakdown = (
            <Box display={"flex"} flexDirection="column">
                <Typography variant="body3">
                    Current epoch: {current} %
                </Typography>
                <Typography variant="body3">
                    Next epoch: {next} %{shareDifferenceToDisplay}
                </Typography>
            </Box>
        );

        const cellTextWithTooltip = (
            <CustomizedTooltip
                triggerElement={cellText}
                text={shareBreakdown}
            />
        );

        return cellTextWithTooltip;
    };

    const generateVotesCell = ({
        amount,
        weight,
    }: {
        amount: number;
        weight: number;
    }) => {
        const votesDetails = (
            <Box
                display={"flex"}
                flexDirection="column"
                alignItems={"flex-end"}
            >
                <Typography variant="body2">
                    {beautifyDecimalAmount(amount, 6)} veDXTR
                </Typography>
                <Typography variant="body2" className="label">
                    {weight.toFixed(2)} %
                </Typography>
            </Box>
        );

        return votesDetails;
    };

    const mappedColumns: GridColumns = [
        {
            field: "poolComposition",
            headerName: "Pool",
            flex: 1.5,
            sortable: false,
            renderCell: (params: any) =>
                generatePoolCompositionCell(params.value),
        },
        {
            field: "share",
            type: "number",
            headerName: "Next Epoch",
            flex: 1,
            sortable: false,
            renderCell: (params: any) => generateShareCell(params.value),
        },
        {
            field: "apr",
            type: "number",
            headerName: "Voting APR",
            flex: 1,
            renderCell: (params: any) => (
                <Typography variant="body2">{params.value} %</Typography>
            ),
        },
        {
            field: "bribesAndFees",
            type: "number",
            headerName: "Bribes + Fees",
            flex: 1,
            sortable: false,
            renderCell: (params: any) =>
                generateBribesAndFeesCell(params.value),
        },
        {
            field: "votes",
            type: "number",
            headerName: "My Votes",
            flex: 1,
            renderCell: (params: any) => generateVotesCell(params.value),
        },
        {
            field: "actions",
            type: "actions",
            flex: 1,
            getActions: (params) => {
                return [
                    <Button
                        variant="contained"
                        className="button-base button-primary blue"
                        onClick={() => onOpenVoteModal(params.row)}
                        disabled={!sufficientVotingPower}
                    >
                        <Typography>Vote</Typography>
                    </Button>,
                ];
            },
        },
    ];

    return mappedColumns;
};

export const GenerateGaugeListTableRows = (
    data: Pool[],
    voteAmount: { [key: string]: string },
    voteWeights: { [key: string]: number },
    searchInput?: string
) => {
    const generatePoolName = (tokens: any) => {
        const tokensList = tokens.map((token: Token) => token.symbol);
        const poolName = tokensList.join(" - ");

        return poolName;
    };

    // Using useMemo because we need to re-map if there are changes on the vote weights
    const mappedRows = useMemo(() => {
        const dataForRows = data.map((pool) => {
            return {
                id: pool.gauge_address,
                poolComposition: {
                    tokens: pool.tokens,
                    name: generatePoolName(pool.tokens),
                },
                share: {
                    current: 71, // Mock data
                    next: 70, // Mock data
                },
                apr: pool.apr, // Not sure if this is the correct data
                bribesAndFees: {
                    amount: 56.86, // Mock data
                    tokens: ["20 LUNA", "25 TEST"], // Mock data
                },
                votes: {
                    amount: pool.gauge_address
                        ? voteAmount[pool.gauge_address] || 0
                        : 0,
                    weight: pool.gauge_address
                        ? voteWeights[pool.gauge_address] || 0
                        : 0,
                },
            };
        });

        return dataForRows;
    }, [data, voteAmount, voteWeights]);

    // Filter the rows based on search input
    const filteredRows = useSearchPool(mappedRows, searchInput);

    return filteredRows || [];
};
