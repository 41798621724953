import { useState } from "react";
import { NavLink } from "react-router-dom";
import { Box, Typography, Button, Drawer } from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import { AppLogo, Footer } from "components";
import { useBreakpoint } from "hooks";

export const Navigation = () => {
  const isTablet = useBreakpoint("tablet");

  const [navigationOpen, setNavigationOpen] = useState(false);

  const navigationItem = (
    <>
      <NavLink end to="/" className="navigation-link">
        <Typography className="navigation-text" variant="body1">
          Swap
        </Typography>
      </NavLink>
      <NavLink to="/pools" className="navigation-link">
        <Typography className="navigation-text" variant="body1">
          Pools
        </Typography>
      </NavLink>
      <NavLink to="/vest" className="navigation-link">
        <Typography className="navigation-text" variant="body1">
          Vest
        </Typography>
      </NavLink>
      <NavLink to="/vote" className="navigation-link">
        <Typography className="navigation-text" variant="body1">
          Vote
        </Typography>
      </NavLink>
      <NavLink to="/bribe" className="navigation-link">
        <Typography className="navigation-text" variant="body1">
          Bribe
        </Typography>
      </NavLink>
    </>
  );

  const navigationWithMenu = (
    <>
      <Button
        className="button-icon-base button-icon-lg"
        onClick={() => setNavigationOpen(true)}
      >
        <MenuRoundedIcon />
      </Button>
      <Drawer
        anchor={"left"}
        open={navigationOpen}
        onClose={() => setNavigationOpen(false)}
        PaperProps={{ className: "navigation-drawer-container" }}
      >
        <Box
          display={"flex"}
          flexDirection="column"
          className="navigation-drawer-item-container"
        >
          <Box display={"flex"} justifyContent="center" mb={"24px"}>
            <AppLogo withLink />
          </Box>
          {navigationItem}
        </Box>
        <Footer />
      </Drawer>
    </>
  );

  const navigationDefault = (
    <nav>
      <Box display={"flex"} alignItems="center">
        {navigationItem}
      </Box>
    </nav>
  );

  return isTablet ? navigationWithMenu : navigationDefault;
};
