import { UIElementProps } from 'components/UIElementProps';
import { useModalContext } from './ModalProvider';

const ModalContainer = (props: UIElementProps) => {
  const { modals } = useModalContext();

  return <>{modals}</>;
};

export { ModalContainer };
