import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider } from '@mui/material/styles';
import { getChainOptions, WalletProvider } from "@terra-money/wallet-provider";

import App from "./App";

import "./styles/main.css";

// Add extra variant for MUI's Typography
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
      body3: true;
      body4: true;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

getChainOptions().then((chainOptions) => {
  root.render(
    <StyledEngineProvider injectFirst>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </StyledEngineProvider>
  );
});
