import { useWallet } from '@terra-money/wallet-provider';
import { Box, Typography } from "@mui/material";
import { UIElementProps } from 'components/UIElementProps';

export const NetworkGuard = (props: UIElementProps) => {
  const { children } = props;
  const { network } = useWallet();

  if (network.name === 'classic') {
    // Classic network is not supported, return message rightaway
    return (
      <Box
        className='app-body-container'
        display={'flex'}
        flexDirection='column'
        justifyContent={'center'}
      >
        <Typography variant="h3" mb={'16px'}>
          Network not Supported
        </Typography>
        <Typography variant="body1">
          The {network.name} network is not supported.
        </Typography>
        <Typography variant="body1">
          Please switch to the Mainnet then refresh this page.
        </Typography>
      </Box>
    );
  } else if (network.name === 'testnet' || network.name === 'localterra') {
    // NOTE: Feel free to uncomment if the UI is bothering too much when developing
    // return (
    //   <>
    //     <Box className='info-panel-top'>
    //       <Typography variant='body1'>You're currently on {network.name} network. Do note that this is a test network.</Typography>
    //     </Box>
    //     {children}
    //   </>
    // );
  }

  return <>{children}</>;
};
