import { TxBuilder } from "libs/transactions";
import {
  DEXTER_ES_MINTER,
  getContractAddress,
} from "../utils/getContractAddress";
import { ConnectedWallet } from "@terra-money/wallet-provider";
import { DEXTER_GAUGE_CONTROLLER } from "utils/getContractAddress";
import { Token } from "types";

export type Votes = {
  weights: number[];
  gauges: string[];
};

export const BuildVoteOnGaugesMsg = (wallet: ConnectedWallet, votes: Votes) => {
  const contract = getContractAddress(
    wallet.network.chainID,
    DEXTER_GAUGE_CONTROLLER
  );
  return TxBuilder.new()
    .execute(wallet.walletAddress, contract, {
      vote: {
        weights: votes.weights.map((w) => Math.floor(w).toString()),
        gauges: votes.gauges,
      },
    })
    .build();
};

export const BuildCreateGaugeMsg = (wallet: ConnectedWallet, pool: string) => {
  const contract = getContractAddress(
    wallet.network.chainID,
    DEXTER_GAUGE_CONTROLLER
  );
  return TxBuilder.new()
    .execute(wallet.walletAddress, contract, {
      create_gauge: {
        pool,
      },
    })
    .build();
};

export const BuildDepositBribeMsg = (
  wallet: ConnectedWallet,
  pool: string,
  bribeToken: Token,
  bribeAmount: string
) => {
  const contract = getContractAddress(
    wallet.network.chainID,
    DEXTER_GAUGE_CONTROLLER
  );
  if (bribeToken.type == "native") {
    return TxBuilder.new()
      .execute(
        wallet.walletAddress,
        contract,
        {
          deposit_bribe: {
            pool,
          },
        },
        { [bribeToken.denom]: Number.parseInt(bribeAmount) }
      )
      .build();
  } else if (bribeToken.type == "cw20") {
    return TxBuilder.new()
      .execute(wallet.walletAddress, bribeToken.token, {
        send: {
          contract,
          amount: bribeAmount,
          msg: Buffer.from(
            JSON.stringify({
              deposit_bribe: {
                pool,
              },
            })
          ).toString("base64"),
        },
      })
      .build();
  }
};
