import { Typography } from "@mui/material";
import { GridColumns } from '@mui/x-data-grid';
import moment from "moment";

export const GenerateVestingListTableColumns = () => {
    const mappedColumns: GridColumns = [
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            renderCell: (params: any) => <Typography variant="body2">{params.value}</Typography>
        },
        {
            field: 'endDate',
            headerName: 'Vest Expires',
            flex: 1,
            renderCell: (params: any) => <Typography variant="body2">{moment(params.value).format("D MMM YYYY, H:mm")}</Typography>
        },
        {
            field: 'amount',
            type: 'number',
            headerName: 'Amount',
            flex: 1,
            renderCell: (params: any) => <Typography variant="body2">{params.value} esDXTR</Typography>
        }
    ];

    return mappedColumns;
};

export const GenerateVestingListTableRows = (data?: any) => {
    // NOTE: These are just examples
    const mappedRows = [
        {
            id: 1,
            amount: 100,
            endDate: '2024-01-31T16:59:59.999Z',
            status: 'Vesting'
        },
        {
            id: 2,
            amount: 150,
            endDate: '2024-01-31T16:59:59.999Z',
            status: 'Vesting'
        }
    ];

    return mappedRows;
}
