import { TxBuilder } from "libs/transactions";
import {
  DEXTER_TOKEN,
  DEXTER_VE_STAKE,
  getContractAddress,
} from "../utils/getContractAddress";
import { ConnectedWallet } from "@terra-money/wallet-provider";

// Lock DXTR token into veDXTR token
// Need to specify the amount of tokens and the number of epochs to lock
export const BuildLockDexterMsg = (
  wallet: ConnectedWallet,
  amount: string,
  epochs_to_lock: number
) => {
  const contract = getContractAddress(wallet.network.chainID, DEXTER_TOKEN);
  const veContract = getContractAddress(
    wallet.network.chainID,
    DEXTER_VE_STAKE
  );
  return TxBuilder.new()
    .execute(wallet.walletAddress, contract, {
      send: {
        contract: veContract,
        amount,
        msg: Buffer.from(
          JSON.stringify({
            bond: {
              epochs_to_lock,
              for_user: null,
            },
          })
        ).toString("base64"),
      },
    })
    .build();
};

export const BuildClaimVeDexterMsg = (wallet: ConnectedWallet) => {
  const contract = getContractAddress(wallet.network.chainID, DEXTER_TOKEN);
  return TxBuilder.new()
    .execute(wallet.walletAddress, contract, {
      withdarw: {},
    })
    .build();
};
