import { Box, Typography } from "@mui/material";
import { NavLink } from 'react-router-dom';
import { ReactComponent as DexterLogo } from '../../assets/logos/logo-dexter.svg';
import { ReactComponent as DexterLogoText } from '../../assets/logos/logo-dexter-text.svg';

type AppLogoProps = {
    withLink?: boolean;
    linkPath?: string;
};

export const AppLogo = (props: AppLogoProps) => {
    const { withLink, linkPath } = props;

    const linkTo = linkPath || '/';

    const logo = (
        <Box display={'flex'} alignItems='center' className="logo-container">
            <DexterLogo className="logo-dexter" />
            <DexterLogoText className="logo-dexter-text" />
        </Box>
    );

    if (withLink) {
        return (
            <Typography variant='h1'>
                <NavLink end to={linkTo}>
                    {logo}
                </NavLink>
            </Typography>
        );
    } else {
        return logo;
    }
};