import { TextField, StandardTextFieldProps } from '@mui/material';
import { useRestrictedInput} from './useRestrictedInput';
import classNames from 'classnames';
import styles from './TextInput.module.sass';

export interface TextInputProps extends StandardTextFieldProps {}

const TextInput = (props: TextInputProps) => {
  const { className, ...rest } = props;
  const inputProps = useRestrictedInput((character) => /[a-zA-Z0-9]+/.test(character));
  return (
    <TextField
      {...rest}
      {...inputProps}
      className={classNames(className, styles.root)}
      variant="outlined"
      autoComplete="off"
    />
  );
};

export { TextInput };
