import { CW20Token, NativeToken, Token } from "types";
import { contractQuery } from "./utils";
import {
  ConnectedWallet,
  useConnectedWallet,
} from "@terra-money/wallet-provider";
import { useQuery, UseQueryResult } from "react-query";
import { QUERY_KEY } from "queries";
import {
  DEXTER_FACTORY,
  getContractAddress,
} from "../utils/getContractAddress";

type AssetInfo =
  | {
      token: { contract_addr: string };
    }
  | {
      native_token: { denom: string };
    };

type PairsResponse = {
  pairs: {
    asset_infos: AssetInfo[];
  }[];
};

export const useDexTokensQuery = (wallet?: ConnectedWallet) => {
  return useQuery([QUERY_KEY.PAIRS, wallet], (): Promise<Token[]> => {
    if (!wallet) {
      console.log("wallet not found");
      return Promise.resolve([]);
    }
    return fetch("https://api-test.dexter.money/api/tokens")
      .then((r) => r.json())
      .then((r: any) => {
            let assets = r["assets"];
            return assets.map((a: any): Token => {
              if (a["type"] === "cw20") {
                return {
                  name: a["name"],
                  decimals: a["decimals"],
                  icon: a["icon"],
                  symbol: a["symbol"],
                  token: a["token"],
                  type: a["type"],
                  key: a["key"],
                  price: a["price"],
                } as CW20Token;
              } else {
                return {
                  name: a["name"],
                  decimals: a["decimals"],
                  icon: a["icon"],
                  symbol: a["symbol"],
                  denom: a["denom"],
                  type: a["type"],
                  key: a["key"],
                  price: a["price"],
                } as NativeToken;
              }
            });
          });
      });
};
