import Big, { BigSource } from "big.js";
import DexterLogo from "../assets/logos/logo-dexter.svg";
import refs from "../refs.terrain.json";

export type TokenBase = {
  key: string;
  name: string;
  symbol: string;
  icon: string;
  decimals: number;
  coinGeckoId?: string;
  balance?: string;
  price?: string;
};

export type NativeToken = TokenBase & {
  type: "native";
  denom: string;
};

export const LUNA: NativeToken = {
  key: "uluna",
  type: "native",
  denom: "uluna",
  name: "LUNA",
  symbol: "LUNA",
  decimals: 6,
  icon: "https://assets.terra.money/icon/svg/LUNA.png",
  coinGeckoId: "terra-luna-2",
};

export interface NativeTokensResponse {
  [tokenAddr: string]: NativeToken;
}

export type CW20Token = TokenBase & {
  type: "cw20";
  protocol: string;
  token: CW20Addr;
};

export interface CW20TokensResponse {
  [tokenAddr: string]: CW20Token;
}

export type IBCToken = TokenBase & {
  type: "ibc";
  path: string;
  base_denom: string;
  denom: string;
};

export interface IBCTokensResponse {
  [tokenAddr: string]: IBCToken;
}

export type Token = NativeToken | CW20Token | IBCToken;

export type TokenWithAmount = Token & {
  amount: string;
};

export type NominalType<T extends BigSource> = { __type: T };

export type Micro = { __micro: true };

export type NoMicro = { __micro?: false };

export type u<T extends BigSource> = T & Micro;

export type WalletAddr = string & NominalType<"WalletAddr">;

export type CW20Addr = string & NominalType<"CW20Addr">;

export const TokenToAssetInfo = (token: Token) => {
  switch (token.type) {
    case "cw20":
      return { token: { contract_addr: token.token } };
    case "ibc":
    case "native":
      return { native_token: { denom: token.denom } };
  }
};

export type SwapAssetType = "from" | "to";

// NOTE: Test token for testing purpose
export const testToken = {
  key: "test",
  name: "Test",
  symbol: "TEST",
  icon: "https://logos-download.com/wp-content/uploads/2016/03/McDonalds_Logo_2018-700x700.png",
  decimals: 6,
  type: "cw20",
  protocol: "",
  token: "terra1eyfccmjm6732k7wp4p6gdjwhxjwsvje44j0hfx8nkgrm8fs7vqfsrv3l3w",
};

// NOTE: Test ibc token
export const ibcToken = {
  "denom": "ibc/B22B4DD21586965DAEF42A7600BA371EA77C02E90FC8A7F2330BF9F9DE129B07",
  "path": "transfer/channel-10",
  "base_denom": "ukuji",
  "symbol": "KUJI",
  "name": "Kujira",
  "icon": "https://assets.terra.money/icon/svg/ibc/KUJI.svg",
  "type": "ibc",
  "key": "ibc/B22B4DD21586965DAEF42A7600BA371EA77C02E90FC8A7F2330BF9F9DE129B07",
  "decimals": 6
};

export const dexterToken = {
  key: "dexter",
  name: "Dexter",
  symbol: "DXTR",
  icon: DexterLogo,
  decimals: 6,
  type: "cw20",
  protocol: "",
  token: refs.localterra["dexter-token"].contractAddresses.default,
};

export const esDexterToken = {
  key: "esdexter",
  name: "esDexter",
  symbol: "esDXTR",
  icon: DexterLogo,
  decimals: 6,
  type: "cw20",
  protocol: "",
  token: refs.localterra["dexter-es-minter"].contractAddresses.default,
};
