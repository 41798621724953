import { useCallback, useMemo } from "react";
import { createReducer } from "react-use";
import { merge } from "lodash";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";

type FormInput<Input> = (input: Partial<Input>) => void;

type Action = { type: string };
type FormAction<State> = Partial<State> & Action;

type FormDispatch<State extends Action> = ThunkDispatch<
  State,
  any,
  FormAction<State>
>;

type FormThunkAction<State> = ThunkAction<
  Promise<void>,
  State,
  any,
  FormAction<State>
>;

export type FormModifier<State> = (state: Partial<State>) => State;

export type FormFunction<Input, State> = (
  input: Partial<Input>,
  getState: () => State,
  modifier: FormModifier<State>
) => Promise<void>;

const reducer = <State>(state: State, action: FormAction<State>) => {
  return merge(state, action);
};

function useForm<Input extends {}, State extends {}>(
  form: FormFunction<Input, State>,
  initialState: State
): [FormInput<Input>, State] {
  const useReducer = useMemo(() => {
    return createReducer<FormAction<State>, State>(thunk as any);
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  const thunkDispatch = dispatch as FormDispatch<State & Action>;

  const update = useCallback(
    async (input: Partial<Input>) => {
      const handler = (input: Partial<Input>): FormThunkAction<State> => {
        return (dispatch, getState) => {
          return form(input, getState, (s) => {
            dispatch(s as any);
            return getState();
          });
        };
      };

      thunkDispatch(handler(input));
    },
    [form, thunkDispatch]
  );

  return useMemo(() => {
    return [update, state];
  }, [update, state]);
}

export { useForm };
