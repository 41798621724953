import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";

import { LUNA, Token } from "types";
import {
    ClickableIcon,
    CustomizedTooltip,
    TokenSelectorForm,
    TextSkeleton,
    WalletGuard,
} from "components";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/icon-settings.svg";
import { ReactComponent as TooltipIcon } from "../../../assets/icons/icon-tooltip.svg";
import { useTokenInfoQuery } from "queries/useTokenInfoQuery";
import { ConnectedWallet } from "@terra-money/wallet-provider";
import { Coin, CreateTxOptions } from "@terra-money/terra.js";
import { useTx, UseTxProps } from "libs/transactions";
import { searchEvents, useSimulateTxQuery } from "queries";
import {
    BuildAddLiqudityMsg,
    BuildWithdrawLiquidityMsg,
} from "transactions/poolTransactions";
import { beautifyAmountByToken, beautifyDecimalAmount, microAmount } from "utils/amountFormatter";

interface WithdrawProps {
    selectedPool: any;
    slippage: string;
    setOpenSlippageConfigModal: () => void;
    wallet?: ConnectedWallet;
}

export const Withdraw = (props: WithdrawProps) => {
    const { selectedPool, slippage, setOpenSlippageConfigModal, wallet } =
        props;

    // Token inputs
    const [withdrawAmount, setWithdrawAmount] = useState<string>("");
    const { isLoading, data: poolToken } = useTokenInfoQuery(
        selectedPool.poolToken,
        wallet
    );

    // Received tokens
    const [receivedTokens, setReceivedTokens] = useState<{
        [key: string]: string;
    }>({});

    // Fetch gas fee
    const [potentialTransaction, setPotentialTransaction] = useState(
        {} as CreateTxOptions
    );
    const {
        isLoading: isLoadingEstimatedFee,
        data: simulatedResponse,
        error: estimatedFeeError,
    } = useSimulateTxQuery(wallet, potentialTransaction);

    useEffect(() => {
        if (wallet && withdrawAmount && poolToken) {
            let msg = BuildWithdrawLiquidityMsg(
                wallet,
                selectedPool.poolToken,
                selectedPool.id,
                microAmount(withdrawAmount, poolToken.decimals)
            );
            setPotentialTransaction(msg);
        }
    }, [wallet, withdrawAmount]);

    useEffect(() => {
        let received = {
            [selectedPool.poolComposition.tokens[0].symbol]: "0",
            [selectedPool.poolComposition.tokens[1].symbol]: "0",
        };
        if (simulatedResponse && simulatedResponse.result) {
            const amountReceived = searchEvents(
                simulatedResponse.result.events,
                "wasm",
                "refund_assets"
            );
            if (amountReceived) {
                const amounts = amountReceived.split(", ");
                for (const amount of amounts) {
                    let coin = Coin.fromString(amount);
                    if (
                        selectedPool.poolComposition.tokens[0].denom ===
                            coin.denom ||
                        selectedPool.poolComposition.tokens[0].token ===
                            coin.denom
                    ) {
                        received[
                            selectedPool.poolComposition.tokens[0].symbol
                        ] = coin.amount.toString();
                    }
                    if (
                        selectedPool.poolComposition.tokens[1].denom ===
                            coin.denom ||
                        selectedPool.poolComposition.tokens[1].token ===
                            coin.denom
                    ) {
                        received[
                            selectedPool.poolComposition.tokens[1].symbol
                        ] = coin.amount.toString();
                    }
                }
            }
        }
        setReceivedTokens(received);
    }, [simulatedResponse]);

    const [withdrawTxResult, withdrawTx] = useTx<UseTxProps>(
        (props: UseTxProps) => {
            return {
                msgs: props.msgs,
            };
        },
        { txKey: "EXECUTE:WITHDRAW" },
        {}
    );

    // Form validation
    const inputIsValid =
        withdrawAmount !== "" && parseFloat(withdrawAmount) > 0;

    const withdrawForm = poolToken && !isLoading && (
        <TokenSelectorForm
            token={poolToken}
            tokenOptions={[poolToken]}
            onSelectToken={() => {}}
            amount={withdrawAmount}
            onChangeAmount={(amount: string) => setWithdrawAmount(amount)}
            label="LP Token"
            withAmountPreset={false}
            disableSelector
            // withSlider
        />
    );

    const tokenReceivedSection = () => {
        const mappedTokenReceivedSections =
            selectedPool?.poolComposition?.tokens.map(
                (token: Token, index: number) => {
                    return (
                        <Box
                            key={index}
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems="center"
                            marginY={1}
                        >
                            <Typography className="label">{`${token.symbol} Received`}</Typography>
                            <Typography>
                                {beautifyDecimalAmount(
                                    receivedTokens[token.symbol] ?? "0",
                                    token.decimals
                                )}{" "}
                                {token.symbol}
                            </Typography>
                        </Box>
                    );
                }
            );
        return mappedTokenReceivedSections;
    };

    // TODO: Display staked LP instead of numbers of token received if there's LP staked.
    const stakedLpSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Typography className="label">Staked LP</Typography>
            <Typography>2.5</Typography>
        </Box>
    );

    const slippageSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Box display={"flex"} alignItems="center">
                <Typography className="label">Slippage</Typography>
                <ClickableIcon
                    variant="button"
                    icon={<SettingsIcon />}
                    onClick={setOpenSlippageConfigModal}
                    className="button-icon-base button-icon"
                />
                <CustomizedTooltip
                    triggerElement={<TooltipIcon />}
                    text="Yo this is a tooltip!"
                />
            </Box>
            <Typography>{slippage} %</Typography>
        </Box>
    );

    const shareOfPoolSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Typography className="label">Share of Pool</Typography>
            <Typography>0.02 %</Typography>
        </Box>
    );

    const additionalDetailsContent = (
        <Box mt="32px">
            {tokenReceivedSection()}
            {stakedLpSection}
            {slippageSection}
            {shareOfPoolSection}
        </Box>
    );

    // TODO: Display unstake button if there's LP staked.
    const unstakeButton = (
        <Button
            variant="contained"
            className="button-base button-primary gradient"
            // disabled={!inputIsValid}
            fullWidth
            onClick={() => {}}
        >
            <Typography variant="button">Unstake LP</Typography>
        </Button>
    );

    const withdrawButton = (
        <Button
            variant="contained"
            className="button-base button-primary gradient"
            disabled={!inputIsValid}
            fullWidth
            onClick={() =>
                withdrawTx({
                    wallet: wallet!,
                    msgs: potentialTransaction.msgs,
                    fee: simulatedResponse?.fee ?? "auto",
                })
            }
        >
            <Typography variant="button">Withdraw</Typography>
        </Button>
    );

    const buttonToDisplay = <WalletGuard element={withdrawButton} />;

    const rawGasFeeData = simulatedResponse?.fee.amount.toData();
    const formattedGasFee = 
        rawGasFeeData 
        ? beautifyAmountByToken(rawGasFeeData[0].amount, LUNA) + ' LUNA'
        : 'N/A';
    const gasFeeToDisplay = isLoadingEstimatedFee ? (
        <TextSkeleton />
    ) : (
        formattedGasFee
    );
    const gasFeeSection = !isLoadingEstimatedFee && (
        <Box
            mt={"8px"}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
        >
            <Typography className="label" mr={"8px"}>
                Gas Fee:
            </Typography>
            <Typography>{gasFeeToDisplay}</Typography>
        </Box>
    );

    const formButton = (
        <Box mt="32px">
            {buttonToDisplay}
            {gasFeeSection}
        </Box>
    );

    return (
        <Box>
            {withdrawForm}
            {additionalDetailsContent}
            {formButton}
        </Box>
    );
};
