import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { NetworkInfo, useWallet } from "@terra-money/wallet-provider";
import { QUERY_KEY } from "./queryKey";
import { LCDClient } from "@terra-money/terra.js/dist/client";
import { CW20Addr, u } from "../types/types";
import Big from "big.js";

interface CW20BalanceResponse {
  balance: u<Big>;
}

export const fetchCW20Balance = async (
  lcdUrl: string,
  chainId: string,
  tokenAddr: string,
  walletAddr: string
): Promise<u<Big>> => {
  const lcd = new LCDClient({
    URL: lcdUrl,
    chainID: chainId,
  });

  const response = await lcd.wasm.contractQuery<CW20BalanceResponse>(
    tokenAddr,
    {
      balance: {
        address: walletAddr,
      },
    }
  );

  return response?.balance ?? 0;
};

export const useCW20BalanceQuery = (
  walletAddr: string,
  tokenAddr: CW20Addr,
  options: Partial<Pick<UseQueryOptions, "enabled">> = { enabled: true }
): UseQueryResult<u<Big>> => {
  const { network } = useWallet();
  return useQuery(
    [QUERY_KEY.CW20_BALANCE, network, tokenAddr, walletAddr],
    ({ queryKey }) => {
      return fetchCW20Balance(
        network.lcd,
        network.chainID,
        tokenAddr,
        walletAddr
      );
    },
    {
      refetchOnMount: false,
      ...options,
    }
  );
};
