import { Box, Typography } from "@mui/material";

import { LockingStats } from "./LockingStats/LockingStats";
import { EpochStats } from "./EpochStats/EpochStats";
import { GaugeList } from "./GaugeList/GaugeList";

export const Vote = () => {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
      width="100%"
    >
      <Typography variant="h1" mb={"48px"}>
        Vote
      </Typography>
      <LockingStats />
      <EpochStats />
      <GaugeList />
    </Box>
  );
};
