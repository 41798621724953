import moment from "moment";

import { beautifyAmount } from "utils/amountFormatter";
import { CardStats, TextSkeleton } from "components";

export const EpochStats = () => {
    // Placeholder data
    const balance = beautifyAmount(1200000);
    const currentDate = new Date();
    const endDate = moment('2023-06-30T16:59:59.999Z');
    const formattedEndDate = moment(endDate).format("D MMM YYYY, H:mm");
    const timeRemaining = endDate.diff(currentDate, 'days');

    const statsItems = [
        {
            label: 'Current Epoch',
            value: '14'
        },
        {
            label: 'Next Epoch Starts In',
            value: `${timeRemaining} days`
        },
        {
            label: 'Next Rewards',
            value: formattedEndDate
        },
    ];

    return (
        <CardStats
            title="Epoch Info"
            items={statsItems}
        />
    );
};