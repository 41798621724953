import { Token } from "types";
import { contractQuery } from "./utils";
import {
    ConnectedWallet,
    useConnectedWallet,
} from "@terra-money/wallet-provider";
import { useQuery, UseQueryResult } from "react-query";
import { QUERY_KEY } from "queries";
import { CW20Addr } from "types/types";

type TokenInfoResponse = {
    name: string;
    symbol: string;
    decimals: number;
    icon: string;
};

export const useTokenInfoQuery = (token: string, wallet?: ConnectedWallet) => {
    return useQuery(
        [QUERY_KEY.PAIRS, token, wallet],
        async (): Promise<Token> => {
            if (!wallet) {
                console.log("wallet not found");
                return Promise.resolve({} as Token);
            }
            let res = await contractQuery<any, TokenInfoResponse>(
                wallet.network,
                token,
                {
                    token_info: {},
                }
            );
            return {
                decimals: res.decimals,
                key: token,
                icon: res.icon,
                name: res.name,
                symbol: res.symbol,
                type: "cw20",
                token: token as CW20Addr,
                protocol: "",
            };
        }
    );
};
