import { Slider } from "@mui/material";

interface CustomizedSliderProps {
    value: number,
    onChange: (value: number) => void;
    min?: number,
    max?: number,
    marks?: {
        value: number,
        label: string
    }[]
};

export const CustomizedSlider = (props: CustomizedSliderProps) => {
    const { value, onChange, min, max, marks } = props;

    return (
        <Slider
            defaultValue={0}
            value={value}
            onChange={(event, value) => onChange(value as number)}
            min={min || 0}
            max={max}
            marks={marks}
        />
    );
};