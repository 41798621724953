import useMediaQuery from '@mui/material/useMediaQuery';

// NOTE: The number here is the max-width value of the device.
// The breakpoints has odd numbers f.ex. 599px to prevent edge case where 600px doesn't fell into any categories.
const breakpoints = {
    mobile: '599px', // xs: 1px - 599px
    tablet: '899px', // sm: 600px - 899px
    laptop: '1199px', // md: 900px - 1199px
    desktop: '1919px' // lg: 1200px ++
};

type DeviceType = "mobile" | "tablet" | "laptop" | "desktop";

export const useBreakpoint = (type: DeviceType) => {
    return useMediaQuery(`(max-width: ${breakpoints[type]})`);
};
