import { Grid } from "@mui/material"
import { useBreakpoint } from 'hooks';
import { AppLogo, Navigation, WalletWidget } from 'components';

export const Header = () => {
    const isLaptop = useBreakpoint('laptop');

    const appLogo = !isLaptop && (
        <Grid lg={2} item>
            <AppLogo withLink />
        </Grid>
    );

    return (
        <header>
            <Grid container my={5} alignItems='center'>
                {appLogo}
                <Grid item xs={2} md={8} lg={6}>
                    <Navigation />
                </Grid>
                <Grid item xs={10} md={4} lg={4} display={'flex'} justifyContent={'flex-end'}>
                    <WalletWidget />
                </Grid>
            </Grid>
        </header>
    );
};