import { Dialog, Typography } from '@mui/material';
import { UIElementProps } from "components/UIElementProps";
import { ReactComponent as CloseIcon } from '../../assets/icons/icon-close.svg';
import { ClickableIcon } from 'components';

interface ModalProps extends UIElementProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    className?: string;
};

export const Modal = (props: ModalProps) => {
    const { open, onClose, title, className, children } = props;

    const modalTitle = title && (
        <Typography variant='h4' mb={'36px'}>
            {title}
        </Typography>
    );

    const closeButton = (
        <ClickableIcon
            variant='button'
            icon={<CloseIcon />}
            onClick={onClose}
            className='modal-close-button'
        />
    );

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{ className: `modal-base ${className}` }}
            BackdropProps={{ className: 'modal-base-background' }}
        >
            {closeButton}
            {modalTitle}
            {children}
        </Dialog>
    );
};