import { Box, Typography, Avatar } from "@mui/material";
import { Token } from "types";
import { CustomizedTooltip } from "components";

// NOTE: All these utils here are to generate commonly used table cell data

export const generatePoolCompositionCell = ({
    tokens,
    name
}: {
    tokens: Token[],
    name: string
}) => {
    const mappedTokenLogos = tokens.map((token, index) => {
        return (
            <Avatar
                key={index}
                src={token.icon}
                alt={token.symbol}
                className="token-icon-sm"
            />
        );
    });

    return (
        <Box display={'flex'} alignItems='center'>
            {mappedTokenLogos}
            <Typography variant='body2' ml='16px'>
                {name}
            </Typography>
        </Box>
    );
};

export const generateBribesAndFeesCell = ({
    amount,
    tokens
}: {
    amount: number,
    tokens: string[]
}) => {

    const cellText = (
        <Typography
            variant="body2"
            sx={{ borderBottom: '1px dotted' }}
        >
            $ {amount}
        </Typography>
    );

    const tokensList = tokens && tokens.map((token: string, index: number) => {
        const tokenName = (
            <Typography variant="body3" key={index}>
                {token}
            </Typography>
        );
        return tokenName;
    });

    const tokenBreakdown = (
        <Box
            display={'flex'}
            flexDirection='column'
            alignItems={'flex-start'}
        >
            {tokensList}
        </Box>
    );

    const cellTextWithTooltip = (
        <CustomizedTooltip
            triggerElement={cellText}
            text={tokenBreakdown}
        />
    );

    return cellTextWithTooltip;
};
