import { useState } from "react";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { Box, Button } from "@mui/material";
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { isUndefined } from "lodash";

import { usePoolsQuery } from "queries";
import { Card, Table, SearchBar } from "components";
import { GenerateBribeListTableColumns, GenerateBribeListTableRows } from "./helper";
import { BribeModal } from "../BribeModal/BribeModal";

export const BribeList = () => {
    const [poolSearchInput, setPoolSearchInput] = useState<string>('');
    const [selectedPool, setSelectedPool] = useState<any>({});
    const [openVoteModal, setOpenVoteModal] = useState<boolean>(false);

    // Check wallet connection
    const connectedWallet = useConnectedWallet();
    const walletAddress = connectedWallet?.walletAddress;
    const walletIsConnected =
    !isUndefined(walletAddress) || !isUndefined(connectedWallet);

    const onOpenVoteModal = (selectedPoolData: any) => {
        setOpenVoteModal(true);
        setSelectedPool(selectedPoolData);
    };

    const { isLoading: isPoolsLoading, data: pools } =
        usePoolsQuery(connectedWallet);

    const filterGroup = (
        <Box
            display={'flex'}
            alignItems='center'
        >
            <SearchBar 
                value={poolSearchInput}
                onChange={setPoolSearchInput}
                customStyles={{ marginRight: '24px' }}
            />
            <Button
                variant="contained"
                className="button-base button-circle grey"
                onClick={() => {}}
            >
                <FilterListRoundedIcon />
            </Button>
        </Box>
    );

    const noResultsText = walletIsConnected ? 'No results.' : 'Please connect your wallet.';
    const bribeListTable = (
        <Table
            rows={GenerateBribeListTableRows(pools ?? [], poolSearchInput)} // TODO: Send real data instead of null once we have real data
            columns={GenerateBribeListTableColumns(onOpenVoteModal)}
            noResultsText={noResultsText}
        />
    );

    const bribeModal = (
        <BribeModal
            open={openVoteModal}
            onClose={() => setOpenVoteModal(false)}
            selectedPool={selectedPool}
        />
    );

    return (
        <Card
            width="100%"
            title="Bribes"
        >
            {filterGroup}
            {bribeListTable}
            {bribeModal}
        </Card>
    );
};