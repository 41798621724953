import { TxBuilder } from "libs/transactions";
import {
  DEXTER_ES_MINTER,
  getContractAddress,
} from "../utils/getContractAddress";
import { ConnectedWallet } from "@terra-money/wallet-provider";

// Vest esDXTR -> DXTR over 4 weeks
export const BuildVestMsg = (wallet: ConnectedWallet, amount: string) => {
  const contract = getContractAddress(wallet.network.chainID, DEXTER_ES_MINTER);
  return TxBuilder.new()
    .execute(wallet.walletAddress, contract, {
      vest: {
        amount,
      },
    })
    .build();
};

// Vest esDXTR -> DXTR immediately but lose 50%
export const BuildVestImmediatelyMsg = (
  wallet: ConnectedWallet,
  amount: string
) => {
  const contract = getContractAddress(wallet.network.chainID, DEXTER_ES_MINTER);
  return TxBuilder.new()
    .execute(wallet.walletAddress, contract, {
      vest_immediately: {
        amount,
      },
    })
    .build();
};

// Claim all DXTR that has successfully vested
export const BuildWithdrawVestedTokenMsg = (wallet: ConnectedWallet) => {
  const contract = getContractAddress(wallet.network.chainID, DEXTER_ES_MINTER);
  return TxBuilder.new()
    .execute(wallet.walletAddress, contract, {
      withdraw: {
        until: null,
      },
    })
    .build();
};

// Bond esDXTR into veDXTR
export const BuildBondEsTokenMsg = (
  wallet: ConnectedWallet,
  amount: string,
  epochs_to_lock: number
) => {
  const contract = getContractAddress(wallet.network.chainID, DEXTER_ES_MINTER);
  return TxBuilder.new()
    .execute(wallet.walletAddress, contract, {
      bond: {
        amount,
        epochs_to_lock,
      },
    })
    .build();
};
