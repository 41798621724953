import { ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import { UIElementProps } from 'components/UIElementProps';

type CopySnackbarProps = UIElementProps & {
    variant: 'neutral' | 'success' | 'error' | 'warning' | 'info';
    icon?: ReactNode;
    text: string;
};

export const Snackbar = (props: CopySnackbarProps) => {
    const { variant, icon, text, children } = props;
    
    let iconToDisplay = icon;
    if (!icon) {
        switch (variant) {
            case 'neutral':
                iconToDisplay = <HourglassTopRoundedIcon />
                break;
            case 'error':
                iconToDisplay = <CancelIcon />
                break;
            case 'success':
            default:
                iconToDisplay = <CheckCircleIcon />
                break;
        }
    }
   
    return (
        <Box
            className={`snackbar-container snackbar-${variant}`}
            display={'flex'}
            alignItems='center'
        >
            {iconToDisplay}
            <Box display={'flex'} flexDirection='column' ml={2}>
                <Typography variant='body1'>
                    {text}
                </Typography>
                {children}
            </Box>
        </Box>
    );
};
