import { useState } from "react";
import moment from "moment";
import { Box, Typography, Avatar, Button } from "@mui/material";

import {
    beautifyAmount,
    beautifyDecimalAmount,
    microAmount,
} from "utils/amountFormatter";
import { CardStats, TextSkeleton } from "components";
import DexterLogo from "../../../assets/logos/logo-dexter.svg";
import { LockingModal, LockActionType } from "../LockingModal/LockingModal";
import { useVeTokenQuery } from "queries/useVeTokenQuery";
import { useConnectedWallet } from "@terra-money/wallet-provider";

export const LockingStats = () => {
    const [openLockActionModal, setOpenLockActionModal] =
        useState<boolean>(false);
    const [lockActionType, setLockActionType] =
        useState<LockActionType>("lock");

    // Placeholder data
    const dexterLocked = beautifyAmount(10000.5123);
    const wallet = useConnectedWallet();

    const { data: veDexterBalance } = useVeTokenQuery(wallet);

    const currentDate = new Date();
    const endDate = moment("2024-01-31T16:59:59.999Z");
    const formattedEndDate = moment(endDate).format("D MMM YYYY, H:mm");
    const timeRemaining = endDate.diff(currentDate, "days");

    const openModal = (type: LockActionType) => {
        setLockActionType(type);
        setOpenLockActionModal(true);
    };

    const lockButton = (
        <Button
            variant="contained"
            className="button-base button-primary blue"
            onClick={() => openModal("lock")}
            sx={{ marginRight: "16px" }}
        >
            <Typography>Lock</Typography>
        </Button>
    );

    const extendButton = (
        <Button
            variant="contained"
            className="button-base button-primary blue"
            onClick={() => openModal("extend")}
        >
            <Typography>Extend</Typography>
        </Button>
    );

    const lockModal = (
        <LockingModal
            open={openLockActionModal}
            onClose={() => setOpenLockActionModal(false)}
            type={lockActionType}
        />
    );

    const cardStatsChildren = (
        <Box display={"flex"} mt="32px">
            {lockButton}
            {extendButton}
        </Box>
    );

    const dexterLockedElement = (
        <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
        >
            <Avatar src={DexterLogo} alt={"DEXTER"} className="token-icon-sm" />
            <Typography variant="subtitle1">{dexterLocked}</Typography>
        </Box>
    );

    const veDexterBalanceElement = (
        <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
        >
            <Avatar src={DexterLogo} alt={"DEXTER"} className="token-icon-sm" />
            <Typography variant="subtitle1">
                {beautifyDecimalAmount(veDexterBalance ?? "0", 6)}
            </Typography>
        </Box>
    );

    const statsItems = [
        {
            label: "veDXTR Balance",
            value: veDexterBalanceElement,
        },
        {
            label: "DXTR Locked",
            value: dexterLockedElement,
        },
        {
            label: "Time Remaining",
            value: `${timeRemaining} days`,
        },
        {
            label: "End Date",
            value: formattedEndDate,
        },
    ];

    return (
        <>
            <CardStats
                title="Voting Wallet"
                items={statsItems}
                children={cardStatsChildren}
            />
            {lockModal}
        </>
    );
};
