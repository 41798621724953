import { ReactElement } from "react";
import {
    Tooltip,
    Fade,
    Typography
  } from "@mui/material";
import { UIElementProps } from "components/UIElementProps";


interface TooltipProps extends UIElementProps {
    triggerElement: ReactElement;
    text: string | ReactElement;
};

export const CustomizedTooltip = (props: TooltipProps) => {
    const { triggerElement, text } = props;

    const textToDisplay = <Typography variant="body3">{text}</Typography>;
    
    return (
        <Tooltip
            arrow
            placement="right"
            TransitionComponent={Fade}
            disableFocusListener
            enterTouchDelay={0}
            componentsProps={{ tooltip: { className: 'tooltip-base' } }}
            className='tooltip-trigger'
            title={textToDisplay}
        >
            {triggerElement}
        </Tooltip>
    );
};