import { Modal } from '@mui/material';
import { ReactNode } from 'react';
import styles from './Dialog.module.sass';

interface DialogProps {
  index: number;
  closeDialog: (returnValue: any) => void;
  children: ReactNode;
}

export const Dialog = (props: DialogProps) => {
  const { index, closeDialog, children } = props;

  return (
    <Modal
      className={styles.root}
      open={true}
      hideBackdrop={index > 0}
      onClose={closeDialog}
    >
      <div className={styles.content}>{children}</div>
    </Modal>
  );
};
