import { useState } from "react";
import { Tabs, Tab } from "@mui/material";

import { useBreakpoint } from "hooks";
import { Modal, SlippageConfigModal, TextSkeleton } from "components";
import { AddLiquidity } from "../AddLiquidity/AddLiquidity";
import { Withdraw } from "../Withdraw/Withdraw";
import { ConnectedWallet } from "@terra-money/wallet-provider";

interface ManagePoolModalProps {
    open: boolean;
    onClose: () => void;
    selectedPool: any;
    wallet?: ConnectedWallet;
}

export const ManagePoolModal = (props: ManagePoolModalProps) => {
    const { open, onClose, selectedPool, wallet } = props;

    const isMobile = useBreakpoint("mobile");

    // Tab
    const [activeTab, setActiveTab] = useState<number>(0);

    // Slippage
    const [openSlippageConfigModal, setOpenSlippageConfigModal] =
        useState<boolean>(false);
    const [slippage, setSlippage] = useState<string>("0.1");

    const slippageConfigModal = (
        <SlippageConfigModal
            open={openSlippageConfigModal}
            onClose={() => setOpenSlippageConfigModal(false)}
            slippageValue={slippage}
            setSlippage={setSlippage}
        />
    );

    const tabs = (
        <Tabs
            value={activeTab}
            onChange={(event, newValue) => setActiveTab(newValue)}
            variant="fullWidth"
            TabIndicatorProps={{ style: { background: "transparent" } }}
            sx={{
                paddingX: `${!isMobile && "40px"}`,
                marginBottom: "40px",
                minHeight: "64px",
            }}
        >
            <Tab label="Add Liquidity" disableRipple />
            <Tab label="Withdraw" disableRipple />
        </Tabs>
    );

    const addLiquidityTabPanel = (
        <AddLiquidity
            selectedPool={selectedPool}
            slippage={slippage}
            setOpenSlippageConfigModal={() => setOpenSlippageConfigModal(true)}
            wallet={wallet}
        />
    );

    const withdrawTabPanel = (
        <Withdraw
            selectedPool={selectedPool}
            slippage={slippage}
            setOpenSlippageConfigModal={() => setOpenSlippageConfigModal(true)}
            wallet={wallet}
        />
    );

    const modalBody = activeTab === 0 ? addLiquidityTabPanel : withdrawTabPanel;

    return (
        <Modal open={open} onClose={() => onClose()} title="Manage Pool">
            {tabs}
            {modalBody}
            {slippageConfigModal}
        </Modal>
    );
};
