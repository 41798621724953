import { Container } from 'components/container/Container';
import { Button, Text } from 'components/primitives';
import { useDialog, DialogProps } from 'libs/dialog';
import { useReadOnlyWalletForm } from './useReadOnlyWalletForm';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { Form } from 'components/form/Form';
import {
  NetworkInfo,
  ReadonlyWalletSession,
} from '@terra-money/wallet-provider';
import { SelectInput } from 'components/primitives/select-input';
import { WalletInput } from './WalletInput';
import { FormControl } from 'components/form-control/FormControl';
import styles from './ReadOnlyWalletDialog.module.sass';

type ReadOnlyWalletDialogProps = {
  networks: NetworkInfo[];
};

export const ReadOnlyWalletDialog = (
  props: DialogProps<ReadOnlyWalletDialogProps, ReadonlyWalletSession>
) => {
  const { closeDialog, networks } = props;

  const [
    input,
    { chainId, walletAddr, walletAddrError, walletAddrValid, submitDisabled },
  ] = useReadOnlyWalletForm(networks);

  return (
    <Form className={styles.root}>
      <Container className={styles.form} direction="column">
        <Text variant="heading1" className={styles.title}>
          View an Address
        </Text>
        <FormControl label="Network" className={styles.network}>
          <SelectInput<string>
            className={styles.inputFields}
            value={chainId}
            onChange={({ target }: SelectChangeEvent<string>) =>
              input({
                chainId: target.value,
              })
            }
            displayEmpty={true}
            fullWidth
          >
            {networks.map(({ chainID, name }) => {
              return (
                <MenuItem key={chainID} value={chainID}>
                  {name} ({chainID})
                </MenuItem>
              );
            })}
          </SelectInput>
        </FormControl>
        <WalletInput
          className={styles.wallet}
          label="Wallet address"
          value={walletAddr}
          onChange={(walletAddr: any) => {
            input({
              walletAddr,
            });
          }}
          addressBookEnabled={false}
          error={walletAddrError}
          valid={walletAddrValid}
        />
      </Container>
      <Container className={styles.formButtons} direction="row">
        <Button
          className={styles.button}
          disabled={submitDisabled}
          onClick={async () => {
            closeDialog(
              {
                network:
                  networks.find((n) => n.chainID === chainId) ?? networks[0],
                terraAddress: walletAddr,
              },
              { closeAll: true }
            );
          }}
        >
          View
        </Button>
        <Button
          className={styles.button}
          variant="secondary"
          onClick={() => closeDialog(undefined)}
        >
          Cancel
        </Button>
      </Container>
    </Form>
  );
};

export const useReadOnlyWalletDialog = () => {
  return useDialog<ReadOnlyWalletDialogProps, ReadonlyWalletSession>(
    ReadOnlyWalletDialog
  );
};
