import { UseQueryResult } from 'react-query';
import { CW20Addr, u } from 'types/types';
import Big from 'big.js';
import {
  useCW20BalanceQuery,
  useNativeBalanceQuery,
  fetchCW20Balance,
  fetchNativeBalance
} from 'queries';
import { CW20Token, IBCToken, NativeToken, Token } from 'types';
import { switchQueries } from './utils';
import { beautifyAmountByToken } from 'utils/amountFormatter';

// Fetch balance, react hook style
export const useFetchTokenBalance = (
  walletAddress: string,
  token: Token
) => {
  const { data: balance } = useBalanceQuery(
    walletAddress as string,
    token
  );

  const formattedBalance = beautifyAmountByToken(balance, token);

  return formattedBalance;
};

// Fetch balance, non-react hook style
export const fetchTokenBalance = async ({
  lcdUrl,
  chainId,
  walletAddr,
  token,
  tokenAddr,
}: FetchTokenBalanceWithToken | FetchTokenBalanceWithTokenAddress) => {
  let balance;
  if (token?.type === 'cw20' || tokenAddr) {
    balance = await fetchCW20Balance(
      lcdUrl,
      chainId,
      (token as CW20Token)?.token || tokenAddr as string,
      walletAddr as string
    );
  } else {
    balance = await fetchNativeBalance(
      lcdUrl,
      chainId,
      (token as NativeToken | IBCToken).denom,
      walletAddr as string,
    );
  }

  const formattedBalance = beautifyAmountByToken(balance, token);

  return formattedBalance;
};

const useBalanceQuery = (
  walletAddr: string,
  token: Token
): UseQueryResult<u<Big>> => {
  const isNative = token.type === 'native';
  const isIBC = token.type === 'ibc';
  const isCw20 = token.type === 'cw20';

  const nativeBalanceQuery = useNativeBalanceQuery(
    walletAddr,
    (token as NativeToken).denom,
    { enabled: isNative }
  );

  const ibcBalanceQuery = useNativeBalanceQuery(
    walletAddr,
    (token as IBCToken).denom,
    { enabled: isIBC }
  );

  const cw20BalanceQuery = useCW20BalanceQuery(
    walletAddr,
    (token as CW20Token).token,
    { enabled: isCw20 }
  );

  return switchQueries([
    { query: nativeBalanceQuery, enabled: isNative },
    { query: ibcBalanceQuery, enabled: isIBC },
    { query: cw20BalanceQuery, enabled: isCw20 },
  ]);
};

interface FetchTokenBalanceBaseRequest {
  lcdUrl: string;
  chainId: string;
  walletAddr: string;
}

// NOTE: Either Token or TokenAddr must exist
interface FetchTokenBalanceWithToken extends FetchTokenBalanceBaseRequest {
  token: Token;
  tokenAddr?: CW20Addr | string;
}

interface FetchTokenBalanceWithTokenAddress extends FetchTokenBalanceBaseRequest {
  token?: Token;
  tokenAddr: CW20Addr | string;
}
