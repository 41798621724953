import type { ComponentType } from 'react';
import { createElement, useCallback } from 'react';
import { useModalContext } from './ModalProvider';
import { Dialog } from './Dialog';

type CloseDialog<Return> = (
  returnValue: Return | undefined,
  opts?: Partial<{ closeAll: boolean }>
) => void;

export type DialogProps<Param = void, Return = void> = Param & {
  closeDialog: CloseDialog<Return>;
};

export type OpenDialog<Param = void, Return = void> = (
  p: Param
) => Promise<Return>;

export function useDialog<Param = void, Return = void>(
  DialogComponent: ComponentType<DialogProps<Param, Return>>
): OpenDialog<
  Param extends DialogProps<infer P, any> ? P : Param,
  Return | undefined
> {
  const context = useModalContext();

  const openDialog: OpenDialog<any, Return | undefined> = useCallback(
    async (props: Param) => {
      const { pushModal, popModal, modals, popAllModals } = context;

      return new Promise<Return | undefined>((resolve) => {
        const closeDialog: CloseDialog<Return> = (returnValue, opts) => {
          if (opts?.closeAll) {
            popAllModals();
          } else {
            popModal();
          }

          resolve(returnValue);
        };

        const component = createElement(Dialog, {
          key: modals.length,
          index: modals.length,
          closeDialog,
          children: createElement(DialogComponent, {
            ...props,
            closeDialog,
          }),
        });

        pushModal(component);
      });
    },
    [DialogComponent, context]
  );

  return openDialog;
}
