import { Box } from "@mui/material";

export const ParticleBackground = () => {
    return (
        <Box>
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
            <Box className="particle-background" />
        </Box>
    );
};