import { useEffect, useState } from "react";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { Box, Typography, Button, Chip } from "@mui/material";
import moment from "moment";
import { isEmpty, isUndefined } from "lodash";

import { Token, dexterToken, esDexterToken } from "types";
import {
    Modal,
    TokenSelectorForm,
    TextSkeleton,
    WalletGuard,
    CustomizedSlider,
} from "components";
import { CreateTxOptions } from "@terra-money/terra.js";
import { searchContractEvents, useSimulateTxQuery } from "queries";
import { BuildLockDexterMsg } from "transactions/veTokenTransactions";
import { BuildBondEsTokenMsg } from "transactions/esTokenTransactions";
import {
    DEXTER_ES_MINTER,
    DEXTER_VE_STAKE,
    getContractAddress,
} from "utils/getContractAddress";
import { useTx, UseTxProps } from "libs/transactions";
import { useVeTokenQuery } from "queries/useVeTokenQuery";

interface LockingActionModalProps {
    open: boolean;
    onClose: () => void;
    type: LockActionType;
}

export type LockActionType = "lock" | "extend";

export const LockingModal = (props: LockingActionModalProps) => {
    const { open, onClose, type } = props;

    // Check wallet connection
    const connectedWallet = useConnectedWallet();
    const walletAddress = connectedWallet?.walletAddress;
    const walletIsConnected =
        !isUndefined(walletAddress) || !isUndefined(connectedWallet);

    const [selectedAsset, setSelectedAsset] = useState<Token | undefined>(
        dexterToken as Token
    );
    const [amount, setAmount] = useState<string>("");
    const [lockPeriod, setLockPeriod] = useState<number>(7);
    const [expectedVeTokens, setExpectedVeTokens] = useState<string>("-");
    const amountInputIsValid = !isEmpty(amount) && parseFloat(amount) > 0;
    const lockPeriodIsValid = lockPeriod >= 7;
    const inputsAreValid = amountInputIsValid && lockPeriodIsValid;
    const { isLoading: isLoadingVeAmount, data: currentVeAmount } =
        useVeTokenQuery(connectedWallet);

    // Fetch gas fee
    const [potentialTransaction, setPotentialTransaction] = useState(
        {} as CreateTxOptions
    );
    const {
        isLoading: isLoadingEstimatedFee,
        data: simulateRes,
        error: estimatedFeeError,
    } = useSimulateTxQuery(connectedWallet, potentialTransaction);

    const [txResult, bondTx] = useTx<UseTxProps>(
        (props: UseTxProps) => {
            return {
                msgs: props.msgs,
            };
        },
        { txKey: "EXECUTE:BOND" },
        {}
    );

    useEffect(() => {
        if (connectedWallet && selectedAsset && open && amount) {
            let msg;
            if (selectedAsset.symbol === dexterToken.symbol) {
                msg = BuildLockDexterMsg(connectedWallet, amount, lockPeriod);
            } else if (selectedAsset.symbol === esDexterToken.symbol) {
                msg = BuildBondEsTokenMsg(connectedWallet, amount, lockPeriod);
            }
            if (msg) {
                setPotentialTransaction({
                    msgs: msg?.msgs,
                });
            }
        }
    }, [connectedWallet, selectedAsset, amount, lockPeriod]);

    useEffect(() => {
        if (
            !isLoadingEstimatedFee &&
            simulateRes &&
            simulateRes.result &&
            connectedWallet
        ) {
            let veBalance = searchContractEvents(
                simulateRes.result.events,
                getContractAddress(
                    connectedWallet.network.chainID,
                    DEXTER_VE_STAKE
                ),
                "new_ve_balance"
            );
            setExpectedVeTokens(veBalance ?? "0");
        } else {
            setExpectedVeTokens("-");
        }
    }, [simulateRes]);

    const tokenSelectorForm = type === "lock" && (
        <Box mb={"32px"}>
            <TokenSelectorForm
                token={selectedAsset}
                onSelectToken={setSelectedAsset}
                tokenOptions={[dexterToken, esDexterToken] as Token[]}
                amount={amount}
                onChangeAmount={(amount: string) => setAmount(amount)}
                label={"Lock Amount"}
                withAmountPreset
            />
        </Box>
    );

    const lockPeriodPresets = (
        <Box
            display={"flex"}
            justifyContent="flex-end"
            alignItems={"center"}
            mt="8px"
        >
            <Chip
                label="28 days"
                data-value={30}
                onClick={() => setLockPeriod(4)}
                sx={{ marginRight: "8px" }}
                className="button-chip"
            />
            <Chip
                label="1 year"
                data-value={30}
                onClick={() => setLockPeriod(52)}
                sx={{ marginRight: "8px" }}
                className="button-chip"
            />
            <Chip
                label="2 years"
                data-value={30}
                onClick={() => setLockPeriod(104)}
                sx={{ marginRight: "8px" }}
                className="button-chip"
            />
            <Chip
                label="4 years"
                data-value={30}
                onClick={() => setLockPeriod(108)}
                className="button-chip"
            />
        </Box>
    );
    const slider = (
        <Box
            className="token-selector-form-container"
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
            flexWrap="nowrap"
        >
            <CustomizedSlider
                value={lockPeriod}
                onChange={(value: number) => setLockPeriod(value)}
                min={4} // Minimum lock period
                max={108} // Maximum lock period (4 years)
            />
        </Box>
    );
    const lockPeriodForm = (
        <Box mb={"32px"}>
            <Typography variant="body1" className="label" mb={"8px"}>
                Lock Period
            </Typography>
            <Typography variant="h3" mb={"16px"}>
                {lockPeriod} days
            </Typography>
            {slider}
            {lockPeriodPresets}
        </Box>
    );

    const currentVeDexterSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Typography className="label">Current veDXTR</Typography>
            <Typography>{currentVeAmount ?? "-"}</Typography>
        </Box>
    );

    const expectedVeDexterPowerSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Typography className="label">Expected veDXTR Power</Typography>
            <Typography>{expectedVeTokens ?? "-"}</Typography>
        </Box>
    );

    const unlockDate = moment(new Date())
        .add(lockPeriod, "days")
        .format("D MMM YYYY");
    const unlockDateSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Typography className="label">Unlock Date</Typography>
            <Typography>{unlockDate}</Typography>
        </Box>
    );

    const additionalDetailsContent = (
        <Box>
            {currentVeDexterSection}
            {expectedVeDexterPowerSection}
            {unlockDateSection}
        </Box>
    );

    const lockingInfoPanel = (
        <Box className="info-panel-red" mt={"32px"}>
            <Typography mb={"8px"}>Lock period cannot be decreased.</Typography>
            <Typography variant="body3">
                Your existing lock period ends at {unlockDate}. Any updates to
                your vote escrow must result in a lockup that ends at or after
                this date. <br />
                You may use a separate wallet in order to maintain multiple
                lockups of varying expiries.
            </Typography>
        </Box>
    );

    const lockButton = (
        <Button
            variant="contained"
            className="button-base button-primary gradient"
            disabled={!inputsAreValid}
            fullWidth
            onClick={() => {
                bondTx({
                    msgs: potentialTransaction.msgs,
                    wallet: connectedWallet!,
                    fee: simulateRes?.fee ?? "auto",
                });
            }}
        >
            <Typography variant="button">Lock</Typography>
        </Button>
    );

    const buttonToDisplay = <WalletGuard element={lockButton} />

    const formButton = <Box mt="32px">{buttonToDisplay}</Box>;

    return (
        <Modal open={open} onClose={() => onClose()} title={"Lock Token"}>
            {tokenSelectorForm}
            {lockPeriodForm}
            {additionalDetailsContent}
            {lockingInfoPanel}
            {formButton}
        </Modal>
    );
};
