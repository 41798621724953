import contracts from "refs.terrain.json";

type ContractRef = {
  [key: string]: {
    [key: string]: {
      codeId: string;
      contractAddresses: {
        default: string;
      };
    };
  };
};

export const DEXTER_FACTORY = "dexter-factory";
export const DEXTER_ROUTER = "dexter-router";
export const DEXTER_ES_MINTER = "dexter-es-minter";
export const DEXTER_VE_STAKE = "dexter-ve-stake";
export const DEXTER_TOKEN = "dexter-token";
export const DEXTER_GAUGE_CONTROLLER = "dexter-gauge-controller";

export const getContractAddress = (
  chainId: string,
  contractName: string
): string => {
  const chainIds = Object.keys(contracts);
  if (!chainIds.includes(chainId)) {
    throw Error(`${chainId} not part of ${chainIds}`);
  }

  let contractAddr = (contracts as unknown as ContractRef)[chainId][
    contractName
  ]?.contractAddresses?.default;

  if (!contractAddr) {
    throw Error(`${contractName} not found`);
  }
  return contractAddr;
};
