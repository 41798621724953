import { UseQueryResult } from "react-query";
import { CW20Addr } from "types/types";
import { LCDClient } from "@terra-money/terra.js/dist/client";
import { NetworkInfo } from "@terra-money/wallet-provider";
import { CW20TokensResponse, IBCTokensResponse } from "types";

type SwitchQueryMetadata<T> = { query: UseQueryResult<T>; enabled: boolean };

export const switchQueries = <T>(
  queries: SwitchQueryMetadata<T>[]
): UseQueryResult<T> => {
  return queries.reduce((acc, curr) => {
    return curr.enabled ? curr : acc;
  }).query;
};

export const mergeQueries = <T>(
  queries: UseQueryResult<T>[],
  mergeData: (acc: T, curr: T) => T
): UseQueryResult<T> => {
  return queries.reduce((acc, curr) => {
    return {
      ...acc,
      isLoading: acc.isLoading || curr.isLoading,
      data: acc.data && curr.data && mergeData(acc.data, curr.data),
    } as UseQueryResult<T>;
  }) as UseQueryResult<T>;
};

export const contractQuery = async <QueryMsg extends {}, QueryResponse>(
  networkOrLCD: NetworkInfo | LCDClient,
  contractAddress: CW20Addr | string,
  msg: QueryMsg,
  defaultValue?: QueryResponse
): Promise<QueryResponse> => {
  const lcd =
    networkOrLCD instanceof LCDClient
      ? networkOrLCD
      : new LCDClient({
          URL: networkOrLCD.lcd,
          chainID: networkOrLCD.chainID,
        });

  try {
    return await lcd.wasm.contractQuery<QueryResponse>(contractAddress, msg);
  } catch (err) {
    //console.log(err);
    if (defaultValue !== undefined) {
      return defaultValue;
    }
    throw err;
  }
};

export const fixTokenResponse = <
  T extends IBCTokensResponse | CW20TokensResponse
>(
  type: "cw20" | "ibc",
  tokens: T,
  accessor: (key: string) => string = (k) => k
) => {
  return Object.keys(tokens).reduce((prev, current) => {
    return {
      ...prev,
      [accessor(current)]: {
        ...tokens[current],
        type,
        key: accessor(current),
        // decimals are optional in the responses but its much easier for us not to worry
        // about optionality within the app so we can standardize the default here
        decimals:
          tokens[current].decimals === undefined ||
          tokens[current].decimals === 0
            ? 6
            : tokens[current].decimals
      },
    };
  }, {});
};
