import { Typography, Button } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";

import { Token } from "types";
import { beautifyAmount, beautifyDecimalAmount } from "utils/amountFormatter";
import { generatePoolCompositionCell } from "utils/tableCellDataGenerator";
import { Pool } from "queries";
import { useSearchPool } from "hooks";

export const GeneratePoolTableColumns = (
    onOpenManagePoolModal: (selectedPoolData: any) => void
) => {
    const generateActionButtonToDisplay = (rowData: any) => {
        // TODO: Logic to decide which button to display based on which pool the user has liquidity on

        const addLiquidityButton = (
            <Button
                variant="contained"
                className="button-base button-primary blue"
                onClick={() => onOpenManagePoolModal(rowData)}
            >
                <Typography>Add Liquidity</Typography>
            </Button>
        );

        // TODO: Add logic to display manage button if user has deposit into a pool
        const manageButton = (
            <Button
                variant="contained"
                className="button-base button-primary gradient"
                onClick={() => onOpenManagePoolModal(rowData)}
            >
                <Typography>Manage</Typography>
            </Button>
        );

        return addLiquidityButton;
    };

    const mappedColumns: GridColumns = [
        {
            field: "poolComposition",
            headerName: "Pool Name",
            flex: 2,
            sortable: false,
            renderCell: (params: any) =>
                generatePoolCompositionCell(params.value),
        },
        {
            field: "apr",
            type: "number",
            headerName: "APR",
            flex: 1,
            renderCell: (params: any) => (
                <Typography variant="body2">{params.value} %</Typography>
            ),
        },
        {
            field: "tvl",
            type: "number",
            headerName: "TVL",
            flex: 1,
            renderCell: (params: any) => (
                <Typography variant="body2">
                    $ {beautifyAmount(params.value)}
                </Typography>
            ),
        },
        {
            field: "totalVolume",
            type: "number",
            headerName: "Volume",
            flex: 1,
            renderCell: (params: any) => (
                <Typography variant="body2">
                    $ {beautifyAmount(params.value)}
                </Typography>
            ),
        },
        {
            field: "myLiquidity",
            type: "number",
            headerName: "My Liquidity",
            flex: 1,
            renderCell: (params: any) => (
                <Typography variant="body2">
                    {beautifyAmount(params.value)} LP
                </Typography>
            ),
        },
        {
            field: "actions",
            type: "actions",
            flex: 2,
            getActions: (params) => [generateActionButtonToDisplay(params.row)],
        },
    ];

    return mappedColumns;
};

export const GeneratePoolTableRows = (data: Pool[], searchInput?: string) => {
    const generatePoolName = (tokens: any) => {
        const tokensList = tokens.map((token: Token) => token.symbol);
        const poolName = tokensList.join(" - ");

        return poolName;
    };

    const mappedRows = data.map((pool) => {
        return {
            id: pool.pool_address,
            poolComposition: {
                tokens: pool.tokens,
                name: generatePoolName(pool.tokens),
            },
            apr: pool.apr,
            tvl: pool.tvl,
            totalVolume: pool.total_volume,
            myLiquidity: beautifyDecimalAmount(pool.pool_token_amount, 6),
            poolToken: pool.pool_token,
        };
    });

    // Filter the rows based on search input
    const filteredRows = useSearchPool(mappedRows, searchInput);

    return filteredRows || [];
};
