import { useMemo } from "react";
import { Typography, Button } from "@mui/material";
import { GridColumns } from '@mui/x-data-grid';

import { generatePoolCompositionCell, generateBribesAndFeesCell } from "utils/tableCellDataGenerator";
import { Token } from "types";
import { Pool } from "queries";
import { useSearchPool } from "hooks";

export const GenerateBribeListTableColumns = (
    onOpenBribeModal: (selectedPoolData: any) => void
) => {
    const mappedColumns: GridColumns = [
        {
            field: 'poolComposition',
            headerName: 'Pool',
            flex: 2,
            sortable: false,
            renderCell: (params: any) => generatePoolCompositionCell(params.value)
        },
        {
            field: 'currentBribes',
            type: 'number',
            headerName: 'Current Bribes',
            flex: 1,
            renderCell: (params: any) => generateBribesAndFeesCell(params.value)
        },
        {
            field: 'nextBribes',
            type: 'number',
            headerName: 'Next Bribes',
            flex: 1,
            renderCell: (params: any) => generateBribesAndFeesCell(params.value)
        },
        {
            field: 'actions',
            type: 'actions',
            flex: 1,
            getActions: (params) => {
                return [(
                    <Button
                        variant="contained"
                        className="button-base button-primary blue"
                        onClick={() => onOpenBribeModal(params.row)}
                    >
                        <Typography>Bribe</Typography>
                    </Button>
                )];
            },
        }
    ];

    return mappedColumns;
};

export const GenerateBribeListTableRows = (data: Pool[], searchInput?: string) => {
    const generatePoolName = (tokens: any) => {
        const tokensList = tokens.map((token: Token) => token.symbol);
        const poolName = tokensList.join(' - ');

        return poolName;
    };

    const mappedRows = data.map((pool) => {
        return {
            id: pool.pool_address,
            poolComposition: {
                tokens: pool.tokens,
                name: generatePoolName(pool.tokens),
            },
            currentBribes: {
                amount: 10.5, // Mock data
                tokens: ['20 LUNA', '25 TEST'] // Mock data
            },
            nextBribes: {
                amount: 11.3, // Mock data
                tokens: ['20 LUNA', '25 TEST'] // Mock data
            },
        };
    });

    // Filter the rows based on search input
    const filteredRows = useSearchPool(mappedRows, searchInput);

    return filteredRows || [];
}
