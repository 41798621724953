import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { isUndefined } from "lodash";

import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { Card, SearchBar, Table } from "components";
import { isEmpty } from "lodash";
import {
    GenerateGaugeListTableColumns,
    GenerateGaugeListTableRows,
} from "./helper";

import { CreateTxOptions } from "@terra-money/terra.js";
import { useTx, UseTxProps } from "libs/transactions";
import { usePoolsQuery, useSimulateTxQuery } from "queries";
import { useGaugeVotesQuery } from "queries/useGaugeVotesQuery";
import { BuildVoteOnGaugesMsg } from "transactions/gaugeTransactions";
import { VoteModal } from "../VoteModal/VoteModal";

export const GaugeList = () => {
    const [poolSearchInput, setPoolSearchInput] = useState<string>("");
    const [selectedPool, setSelectedPool] = useState<any>({});
    const [openVoteModal, setOpenVoteModal] = useState<boolean>(false);
    const [voteAmount, setVoteAmount] = useState<{ [key: string]: string }>({});
    const [totalVoteAmount, setTotalVoteAmount] = useState<number>(0);
    const [voteWeights, setVoteWeights] = useState<{ [key: string]: number }>(
        {}
    );
    const [votingPowerUsed, setVotingPowerUsed] = useState<number>(0);
    const voteIsSubmitted = totalVoteAmount > 0;
    const sufficientVotingPower = votingPowerUsed !== 100 && votingPowerUsed < 100;

    // Check wallet connection
    const connectedWallet = useConnectedWallet();
    const walletAddress = connectedWallet?.walletAddress;
    const walletIsConnected =
    !isUndefined(walletAddress) || !isUndefined(connectedWallet);

    const { isLoading: isPoolsLoading, data: pools } =
        usePoolsQuery(connectedWallet);

    const { data: currentGaugeVotes } = useGaugeVotesQuery(connectedWallet);

    // Fetch gas fee
    const [potentialTransaction, setPotentialTransaction] = useState(
        {} as CreateTxOptions
    );
    const {
        isLoading: isLoadingEstimatedFee,
        data: simulateRes,
        error: estimatedFeeError,
    } = useSimulateTxQuery(connectedWallet, potentialTransaction);

    // Fetch current gauge info and update the states
    useEffect(() => {
        if (currentGaugeVotes) {
            const votes = {} as { [key: string]: number };
            const totalVotes = Object.values(currentGaugeVotes).reduce(
                (p, c) => {
                    return p + Number.parseInt(c);
                },
                0
            );
            for (const addr of Object.keys(currentGaugeVotes)) {
                const weight = (Number.parseInt(currentGaugeVotes[addr]) / totalVotes) *
                100;
                votes[addr] = Math.round(weight);
            }
            setVoteWeights(votes);
            setTotalVoteAmount(totalVotes);
            setVoteAmount(currentGaugeVotes);
        }
    }, [currentGaugeVotes]);

    // Calculate total voting power used and voting power
    useEffect(() => {
        if (!isEmpty(voteWeights)) {
            const votingPowerUsed = Object.values(voteWeights).reduce(
                (key, value) => key + value,
                0
            );
            setVotingPowerUsed(votingPowerUsed);
            const v = Object.keys(voteWeights).reduce((p, c) => {
                p[c] = ((voteWeights[c] * totalVoteAmount) / 100).toString();
                return p;
            }, {} as { [key: string]: string });
            setVoteAmount(v);
        }
    }, [voteWeights]);

    // Once voting power is 100% used, set a potential transaction
    useEffect(() => {
        if (votingPowerUsed === 100 && connectedWallet) {
            let msg = BuildVoteOnGaugesMsg(connectedWallet, {
                gauges: Object.keys(voteWeights),
                weights: Object.values(voteWeights).map(Math.round),
            });
            setPotentialTransaction({
                msgs: msg.msgs,
            });
        }
    }, [votingPowerUsed]);

    const [response, voteTx] = useTx<UseTxProps>(
        (props: UseTxProps) => {
            return {
                msgs: props.msgs,
            };
        },
        { txKey: "EXECUTE:BOND" },
        {}
    );

    const onOpenVoteModal = (selectedPoolData: any) => {
        setOpenVoteModal(true);
        setSelectedPool(selectedPoolData);
    };

    const votePool = (selectedPoolAddress: string, weight: number) => {
        setVoteWeights({
            ...voteWeights,
            [selectedPoolAddress]: weight,
        });
        setOpenVoteModal(false);
    };

    const resetVote = () => {
        setVoteWeights({});
        setVoteAmount({});
        setVotingPowerUsed(0);
    };

    const filterGroup = (
        <Box display={"flex"} alignItems="center">
            <SearchBar
                value={poolSearchInput}
                onChange={setPoolSearchInput}
                customStyles={{ marginRight: "24px" }}
            />
            <Button
                variant="contained"
                className="button-base button-circle grey"
                onClick={() => {}}
            >
                <FilterListRoundedIcon />
            </Button>
        </Box>
    );

    // NOTE: Since we need to show all gauges weights here, prob we need to ditch pagination later
    const noResultsText = walletIsConnected ? 'No results.' : 'Please connect your wallet.';
    const gaugeListTable = (
        <Table
            rows={GenerateGaugeListTableRows(
                pools ?? [],
                voteAmount,
                voteWeights,
                poolSearchInput
            )}
            columns={GenerateGaugeListTableColumns(onOpenVoteModal, sufficientVotingPower)}
            noResultsText={noResultsText}
        />
    );

    const voteModal = (
        <VoteModal
            open={openVoteModal}
            onClose={() => setOpenVoteModal(false)}
            selectedPool={selectedPool}
            votePool={votePool}
            voteWeights={voteWeights}
            votingPowerUsed={votingPowerUsed}
        />
    );

    const resetVotesButton = (
        <Button
            variant="contained"
            className="button-base button-primary blue"
            disabled={isEmpty(voteWeights)}
            onClick={() => resetVote()}
            sx={{ mr: "8px" }}
        >
            <Typography variant="button">Reset</Typography>
        </Button>
    );

    const castVotesButton = (
        <Button
            variant="contained"
            className="button-base button-primary gradient"
            disabled={voteIsSubmitted && sufficientVotingPower}
            onClick={() => {
                voteTx({
                    fee: simulateRes?.fee ?? "auto",
                    msgs: potentialTransaction.msgs,
                    wallet: connectedWallet!,
                });
            }}
        >
            <Typography variant="button">Cast Votes</Typography>
        </Button>
    );

    const votingPanel = (
        <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems="center"
        >
            <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography className="label" mr="8px">
                    Voting Power Used:
                </Typography>
                <Typography>{Math.round(votingPowerUsed)} %</Typography>
            </Box>
            <Box>
                {resetVotesButton}
                {castVotesButton}
            </Box>
        </Box>
    );

    const votingPowerInfo = (
        <Card customClassName="fixed-bottom" customPadding="24px 32px">
            {votingPanel}
        </Card>
    );

    return (
        <>
            <Card
                width="100%"
                title="Gauges"
                subtitle="Vote for gauge weight with your veDEXTER tokens. Gauge weights are used to determine how much DEXTER each pool gets."
            >
                {filterGroup}
                {gaugeListTable}
                {voteModal}
            </Card>
            {votingPowerInfo}
        </>
    );
};
