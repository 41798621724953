import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";

import { useBreakpoint } from "hooks";
import { UIElementProps } from "components/UIElementProps";
import { Card } from "../cardContainer/CardContainer";

interface CardStatsProps extends UIElementProps {
    title?: string,
    items: {
        label?: string;
        value: ReactElement | string;
    }[]
};

export const CardStats = (props: CardStatsProps) => {
    const { title, items, children } = props;

    const isMobile = useBreakpoint('mobile');

    const mappedItem = items.map((item: any, index: number) => {
        const label = (
            <Typography className="label">{item.label}</Typography>
        );

        const value = typeof item.value === 'string'
            ? <Typography variant="subtitle1">{item.value}</Typography>
            : item.value;

        return (
            <Box
                key={index}
                display={'flex'}
                flexDirection='column'
                alignItems={'flex-start'}
                className='card-stats-item'
            >
                {label}
                {value}
            </Box>
        );
    });

    return (
        <Card width="100%" title={title}>
            <Box
                display={'flex'}
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent='space-between'
                alignItems={'flex-start'}
            >
                {mappedItem}
            </Box>
            {children}
        </Card>
    );
};