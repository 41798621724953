import { CardStats, TextSkeleton } from "components";
import { beautifyAmount } from "utils/amountFormatter";

export const Stats = () => {
    // Placeholder numbers
    const totalLiquidity = beautifyAmount(1200000);
    const dailyVolume = beautifyAmount(340000);
    const tokenPrice = beautifyAmount(1.52);

    const statsItems = [
        {
            label: 'Total Liquidity',
            value: `$ ${totalLiquidity}`
        },
        {
            label: '24h Volume',
            value: `$ ${dailyVolume}`
        },
        {
            label: 'Token Price',
            value: `$ ${tokenPrice}`
        }
    ];

    return (
        <CardStats
            title="Overview"
            items={statsItems}
        />
    );
};