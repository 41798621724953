import { Box, Grid, Container, Typography } from "@mui/material"
import { ClickableIcon } from 'components';
import { ReactComponent as DexterLogo } from '../../assets/logos/logo-dexter.svg';
import { ReactComponent as DexterLogoText } from '../../assets/logos/logo-dexter-text.svg';
import ArticleIcon from '@mui/icons-material/Article';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';

import { useBreakpoint } from 'hooks';

export const Footer = () => {
    const isTablet = useBreakpoint('tablet');

    // TODO: Extract this out to a component like Socials Links or something
    const footerMobile = (
        <Box 
            display='flex'
            justifyContent={'space-between'}
            alignItems='center'
            mt={'24px'}
        >
            <ClickableIcon
                variant='externalLink'
                icon={<ArticleIcon />}
                linkTo='#'
                className='external-link-footer'
            />
            <ClickableIcon
                variant='externalLink'
                icon={<GitHubIcon />}
                linkTo='#'
                className='external-link-footer'
            />
            <ClickableIcon
                variant='externalLink'
                icon={<TwitterIcon />}
                linkTo='#'
                className='external-link-footer'
            />
            <ClickableIcon
                variant='externalLink'
                icon={<TelegramIcon />}
                linkTo='#'
                className='external-link-footer'
            />
        </Box>
    );

    const footerDesktop = (
        <footer className='footer-container'>
            <Container>
                <Grid container py={'32px'} mt='40px' display='flex' justifyContent={'space-between'}>
                    <Grid
                        item
                        display={'flex'}
                        flexDirection='column'
                        alignItems={'center'}
                    >
                        <DexterLogo className="logo-dexter-footer" />
                        <DexterLogoText className="logo-dexter-text-footer" />
                        <Box mt={'16px'}>
                            <ClickableIcon
                                variant='externalLink'
                                icon={<ArticleIcon />}
                                linkTo='#'
                                className='external-link-footer'
                            />
                            <ClickableIcon
                                variant='externalLink'
                                icon={<GitHubIcon />}
                                linkTo='#'
                                className='external-link-footer'
                            />
                            <ClickableIcon
                                variant='externalLink'
                                icon={<TwitterIcon />}
                                linkTo='#'
                                className='external-link-footer'
                            />
                            <ClickableIcon
                                variant='externalLink'
                                icon={<TelegramIcon />}
                                linkTo='#'
                                className='external-link-footer'
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        display={'flex'}
                        flexDirection='column'
                    >
                        <Typography variant="h4" mb={'24px'}>Community</Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Discord
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Twitter
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Telegram
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        display={'flex'}
                        flexDirection='column'
                    >
                        <Typography variant="h4" mb={'24px'}>Tools</Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Analytics
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Github
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Tradingview
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            DefiLlama
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Terra Finder
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        display={'flex'}
                        flexDirection='column'
                    >
                        <Typography variant="h4" mb={'24px'}>Participate</Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Vote
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Apply for Collab
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Apply for Token Listing
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Bug Bounty
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        display={'flex'}
                        flexDirection='column'
                    >
                        <Typography variant="h4" mb={'24px'}>Info</Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            About
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Medium
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Docs
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            FAQs
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Terms and Conditions
                        </Typography>
                        <Typography
                            variant="body1"
                            component={'a'}
                            href='#'
                            target={'_blank'}
                            mb='8px'
                            className='external-link-footer'
                        >
                            Contact Us
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );

    return isTablet ? footerMobile : footerDesktop;
};