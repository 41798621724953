import { useState, useMemo, BaseSyntheticEvent } from "react";
import { useConnectedWallet, useWallet } from "@terra-money/wallet-provider";
import {
    Box,
    Grid,
    TextField,
    Avatar,
    Typography,
    Button,
    Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { isUndefined } from "lodash";
import classNames from "classnames";

import { ReactComponent as WalletIcon } from "../../assets/icons/icon-wallet.svg";
import { useTokens, useBreakpoint } from "hooks";
import {
    useDexTokensQuery,
    useFetchTokenBalance,
    useFormattedPricesQuery,
    fetchTokenBalance,
} from "queries";
import { LUNA, SwapAssetType, Token } from "types";
import { floatRegex } from "variables";
import { beautifyAmount } from "utils/amountFormatter";
import {
    Modal,
    TextSkeleton,
    SpinningLogo,
    SearchBar,
    CustomizedSlider,
} from "components";

interface TokenSelectorModalProps {
    open: boolean;
    onClose: () => void;
    onSelectToken: (token: Token | undefined) => void;
    // NOTE: This is a fixed pre-selected tokens available as options
    tokenOptions?: Token[];
    // NOTE: This is to exclude a specific token
    excludedToken?: Token;
}

const TokenSelectorModal = (props: TokenSelectorModalProps) => {
    const { open, onClose, onSelectToken, tokenOptions, excludedToken } = props;

    // Wallet
    const connectedWallet = useConnectedWallet();
    const walletAddress = connectedWallet?.walletAddress;
    const { network } = useWallet();

    // Fetching tokens that the DEX support
    const { isLoading: isLoadingDexTokens, data: dexTokens } =
        useDexTokensQuery(connectedWallet);

    // Mapped tokens in array for easier mapping
    const [tokensList, setTokensList] = useState<Token[]>([]);

    // Search token
    const [tokenSearchInput, setTokenSearchInput] = useState<string>("");

    // Convert fetched tokens into array for easier filtering purpose
    useMemo(async () => {
        // If pre-selected tokenOptions is not provided, map the fetched tokens list and display it
        const tokensListToMap = tokenOptions || dexTokens || [];

        // Map tokens and fetch the balance of each token
        let tokensList = await Promise.all(
            tokensListToMap.map(async (token) => {
                const balance = await fetchTokenBalance({
                    lcdUrl: network.lcd,
                    chainId: network.chainID,
                    walletAddr: walletAddress as string,
                    token,
                });

                return {
                    ...token,
                    balance: balance || "0",
                };
            })
        );

        // Filter out token that need to be excluded (if any)
        if (excludedToken && tokensList.length > 0) {
            tokensList = tokensList.filter(token => token.symbol !== excludedToken.symbol);
        }

        setTokensList(tokensList);
    }, [dexTokens, tokenOptions, excludedToken]);

    const closeTokenSelector = () => {
        onClose();
        setTokenSearchInput("");
    };

    // Get full token details when selected
    const fetchSelectedTokenDetails = (event: BaseSyntheticEvent) => {
        const tokenSelected = event.currentTarget.dataset.value;
        const tokenDetails = tokensList.find(
            (token) => token.symbol === tokenSelected
        );

        onSelectToken(tokenDetails);
        setTokenSearchInput("");
    };

    // Map and filter list of tokens to display
    const mappedTokensList = useMemo(() => {
        let tokensListToMap = tokensList;

        if (tokenSearchInput) {
            const lowerCasedSearchInput = tokenSearchInput.toLowerCase();
            tokensListToMap = tokensList.filter((token) => {
                const lowerCasedTokenSymbol = token.symbol.toLowerCase();
                const lowerCasedTokenName = token.name.toLowerCase();
                return (
                    lowerCasedTokenSymbol.includes(lowerCasedSearchInput) ||
                    lowerCasedTokenName.includes(lowerCasedSearchInput)
                );
            });
        }

        const mappedTokens = tokensListToMap.map((token, index) => {
            return (
                <Box
                    key={index}
                    data-value={token.symbol}
                    display={"flex"}
                    justifyContent="space-between"
                    alignItems="center"
                    className="token-options-item"
                    onClick={(event) => fetchSelectedTokenDetails(event)}
                >
                    <Box
                        display={"flex"}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Avatar
                            src={token.icon}
                            alt={token.symbol}
                            className="token-icon-lg"
                        />
                        <Box flexDirection={"column"}>
                            <Typography variant="body1">
                                {token.symbol}
                            </Typography>
                            <Typography variant="body3">
                                {token.name}
                            </Typography>
                        </Box>
                    </Box>
                    <Typography variant="body1">
                        {token.balance || "-"}
                    </Typography>
                </Box>
            );
        });
        return mappedTokens;
    }, [tokenSearchInput, tokensList, dexTokens]);

    const tokensListToDisplay = isLoadingDexTokens ? (
        <SpinningLogo size="lg" />
    ) : (
        mappedTokensList
    );

    const searchBar = (
        <SearchBar value={tokenSearchInput} onChange={setTokenSearchInput} />
    );

    const tokenSelectorModal = (
        <Modal open={open} onClose={closeTokenSelector} title="Select a Token">
            {searchBar}
            <Box className="token-options-container" mt={"24px"}>
                {tokensListToDisplay}
            </Box>
        </Modal>
    );

    return tokenSelectorModal;
};

interface TokenSelectorFormProps {
    // Selector Button
    token: Token | undefined;
    tokenOptions?: Token[];
    excludedToken?: Token;
    onClickSelector?: (swapAssetType: SwapAssetType) => void;
    onSelectToken?: (token: Token | undefined) => void;
    disableSelector?: boolean;
    withSelectorOnly?: boolean;

    // Amount Input
    amount?: string | undefined;
    onChangeAmount?: (amount: string) => void;
    disableInput?: boolean;
    isLoading?: boolean;

    // Top Section
    label?: string;

    // Bottom Section
    withAmountPreset?: boolean;
    withSlider?: boolean;
}

export const TokenSelectorForm = (props: TokenSelectorFormProps) => {
    const {
        // Selector Button
        token,
        tokenOptions,
        excludedToken,
        onSelectToken,
        disableSelector,
        withSelectorOnly,

        // Amount Input
        amount,
        onChangeAmount,
        disableInput,
        isLoading,

        // Top Section
        label,

        // Additional Functionality
        withAmountPreset,
        withSlider,
    } = props;

    const isTablet = useBreakpoint("tablet");

    // Wallet
    const connectedWallet = useConnectedWallet();
    const walletAddress = connectedWallet?.walletAddress;

    const [openTokenSelectorModal, setOpenTokenSelectorModal] =
        useState<boolean>(false);
    const [sliderAmount, setSliderAmount] = useState<number>(0);

    // Fetching token's balance on wallet
    const balance = useFetchTokenBalance(
        walletAddress as string,
        token || LUNA // NOTE: Providing default token to prevent breaking when token is undefined (Token is not selected yet)
    );
    const beautifiedBalance = balance ? beautifyAmount(balance) : 0;
    const hasBalance = balance && parseFloat(balance) > 0;
    const insufficientBalance =
        amount && balance && parseFloat(amount) > parseFloat(balance);

    // Fetch and calculate total estimated value
    const marketPrice = useFormattedPricesQuery();
    const totalEstimatedValue =
        amount && marketPrice && parseFloat(amount) * parseFloat(marketPrice);
    const beautifiedTotalEstimatedValue =
        totalEstimatedValue && beautifyAmount(totalEstimatedValue);

    // Functions to handle form functionality and changes
    const processSelectToken = (token: Token | undefined) => {
        onSelectToken && onSelectToken(token);

        setOpenTokenSelectorModal(false);
    };

    const processChangeAmount = (event: BaseSyntheticEvent) => {
        const amount = event.target.value;

        if (floatRegex.test(amount)) {
            onChangeAmount && onChangeAmount(amount);
        }
    };

    const setAmountByPercentage = (percentage: number) => {
        const floatBalance = balance && parseFloat(balance);
        const presetBalance = floatBalance && floatBalance * (percentage / 100);
        onChangeAmount && onChangeAmount(presetBalance?.toString() || "");
    };

    const onDragSlider = (percentage: number) => {
        setSliderAmount(percentage);
        setAmountByPercentage(percentage);
    };

    // Total value section
    const totalValueSection = amount && (
        <Typography variant="body4" className="label">
            ~ $ {beautifiedTotalEstimatedValue}
        </Typography>
    );

    // Form label and token's balance on wallet
    const tokenBalance = token && (
        <Box
            display={"flex"}
            alignItems={"center"}
            onClick={() => setAmountByPercentage(100)}
        >
            <WalletIcon width={"18px"} />
            <Typography ml={"8px"}>
                {beautifiedBalance} {token?.symbol}
            </Typography>
        </Box>
    );
    const topSection = label && (
        <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
            mb="8px"
        >
            <Typography variant="body1" className="label">
                {label}
            </Typography>
            {tokenBalance}
        </Box>
    );

    // Amount presets
    const bottomSection = withAmountPreset && hasBalance && (
        <Box
            display={"flex"}
            justifyContent="flex-end"
            alignItems={"center"}
            mt="8px"
        >
            <Chip
                label="HALF"
                data-value={50}
                onClick={() => setAmountByPercentage(50)}
                sx={{ marginRight: "8px" }}
                className="button-chip"
            />
            <Chip
                label="MAX"
                data-value={100}
                onClick={() => setAmountByPercentage(100)}
                className="button-chip"
            />
        </Box>
    );

    // Slider
    const slider = (
        <CustomizedSlider value={sliderAmount} onChange={onDragSlider} />
    );
    const sliderSection = withSlider && (
        <Grid
            className="token-selector-form-container"
            container
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
            flexWrap="nowrap"
            mt={"32px"}
        >
            <Grid
                item
                xs={6}
                md={8}
                display="flex"
                justifyContent={"flex-start"}
            >
                {slider}
            </Grid>
            <Grid
                item
                xs={6}
                md={4}
                display="flex"
                flexDirection={"column"}
                alignItems="flex-end"
            >
                <Typography variant="h3">{sliderAmount} %</Typography>
            </Grid>
        </Grid>
    );

    // Token selector and amount input field
    const buttonTextClassNames = classNames({
        "grey-text": !token,
    });
    const tokenLogo = token && (
        <Avatar
            src={token?.icon}
            alt={token?.symbol}
            className="token-icon-sm"
        />
    );
    const expandIcon = !disableSelector && (
        <ExpandMoreIcon sx={{ marginRight: isTablet ? "4px" : "8px" }} />
    );
    const tokenSelectorButton = (
        <Button
            variant="outlined"
            className="button-base button-token-selector"
            onClick={() => setOpenTokenSelectorModal(true)}
            disabled={disableSelector}
            fullWidth={withSelectorOnly}
        >
            <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems="center"
            >
                {expandIcon}
                <Box display={"flex"} alignItems="center">
                    {tokenLogo}
                    <Typography
                        variant="body1"
                        className={buttonTextClassNames}
                    >
                        {token?.symbol || "Select"}
                    </Typography>
                </Box>
            </Box>
        </Button>
    );

    const tokenSelectorModal = (
        <TokenSelectorModal
            open={openTokenSelectorModal}
            onClose={() => setOpenTokenSelectorModal(false)}
            onSelectToken={(token: Token | undefined) =>
                processSelectToken(token)
            }
            tokenOptions={tokenOptions}
            excludedToken={excludedToken}
        />
    );

    const tokenAmountInput = (
        <TextField
            variant="standard"
            type={"text"}
            placeholder={isLoading ? "" : "0.0"}
            value={isLoading || isUndefined(amount) ? "" : amount}
            onChange={(event: BaseSyntheticEvent) => processChangeAmount(event)}
            disabled={disableInput}
            InputProps={{
                disableUnderline: true,
                inputProps: { className: "token-selector-input" },
                endAdornment: isLoading && <TextSkeleton />,
            }}
            sx={{ maxWidth: "240px" }}
        />
    );

    const tokenSelectorFormSelectorSection = (
        <Grid
            item
            xs={withSelectorOnly ? 12 : 6}
            md={withSelectorOnly ? 12 : 5}
            display="flex"
            justifyContent={"flex-start"}
        >
            {tokenSelectorButton}
        </Grid>
    );

    const tokenSelectorFormInputSection = !withSelectorOnly && (
        <Grid
            item
            xs={6}
            md={7}
            display="flex"
            flexDirection={"column"}
            alignItems="flex-end"
        >
            {tokenAmountInput}
            {totalValueSection}
        </Grid>
    );

    const formContainerClassNames = classNames(
        {
            invalid: insufficientBalance && !disableInput,
        },
        "token-selector-form-container"
    );
    const formGroup = (
        <Grid
            className={formContainerClassNames}
            container
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
            flexWrap="nowrap"
        >
            {tokenSelectorFormSelectorSection}
            {tokenSelectorFormInputSection}
        </Grid>
    );

    return (
        <>
            {topSection}
            {formGroup}
            {bottomSection}
            {sliderSection}
            {tokenSelectorModal}
        </>
    );
};
