export enum QUERY_KEY {
    LUNA_BALANCE = "QUERY:LUNA_BALANCE",
    NATIVE_TOKENS = "QUERY:NATIVE_TOKENS",
    CW20_TOKENS = "QUERY:CW20_TOKENS",
    CW20_BALANCE = "QUERY:CW20_BALANCE",
    IBC_TOKENS = "QUERY:IBC_TOKENS",
    PRICES = "QUERY:PRICES",
    PAIRS = "QUERY:PAIRS",
    PAIR = "QUERY:PAIR",
    POOLS = "QUERY:POOLS",
    VE_AMOUNT = "QUERY:VE_AMOUNT",
    GAUGE_VOTES = "QUERY:GAUGE_VOTES",
    SIMULATE_SWAP_OPERATIONS = "QUERY:SIMULATE_SWAP_OPERATIONS",
    ESTIMATE_FEE = "QUERY:ESTIMATE_FEE",
}
