import { useState } from "react";
import { Box, Typography, Button, Chip } from "@mui/material";
import { isEmpty } from "lodash";
import moment from "moment";

import { generatePoolCompositionCell } from "utils/tableCellDataGenerator";
import { dexterToken, Token } from "types";
import {
    Modal,
    WalletGuard,
    TokenSelectorForm,
    TextSkeleton,
    CustomizedSlider
} from "components";

interface VoteModalProps {
    open: boolean;
    onClose: () => void;
    selectedPool: any;
}

export const BribeModal = (props: VoteModalProps) => {
    const { open, onClose, selectedPool } = props;

    const [bribeAmount, setBribeAmount] = useState<string>('');
    const [epochAmount, setEpochAmount] = useState<number>(1);

    const tokenOptions = selectedPool?.poolComposition;
    const poolName = tokenOptions && generatePoolCompositionCell(tokenOptions);

    const amountInputIsValid = !isEmpty(bribeAmount) && parseFloat(bribeAmount) > 0;
    const epochAmountIsValid = epochAmount >= 1;
    const inputsAreValid = amountInputIsValid && epochAmountIsValid;

    const selectedPoolSection = (
        <Box mb={'32px'}>
            <Typography className="label" mb={'8px'}>Pool</Typography>
            {poolName}
        </Box>
    );

    const bribeAmountForm = (
        <TokenSelectorForm
            token={dexterToken as Token}
            disableSelector
            amount={bribeAmount}
            onChangeAmount={(amount: string) => setBribeAmount(amount)}
            label={'Bribe Amount'}
            withAmountPreset
        />
    );

    const bribeButton = (
        <Button
            variant="contained"
            className="button-base button-primary gradient"
            disabled={!inputsAreValid}
            fullWidth
            onClick={() => {}}
        >
            <Typography variant="button">Bribe</Typography>
        </Button>
    );

    const epochAmountPresets = (
        <Box
            display={'flex'}
            justifyContent='flex-end'
            alignItems={'center'}
            mt='8px'
        >
            <Chip
                label="10"
                data-value={10}
                onClick={() => setEpochAmount(10)}
                sx={{ marginRight: '8px' }}
                className='button-chip'
            />
            <Chip
                label="25"
                data-value={25}
                onClick={() => setEpochAmount(25)}
                sx={{ marginRight: '8px' }}
                className='button-chip'
            />
            <Chip
                label="50"
                data-value={50}
                onClick={() => setEpochAmount(50)}
                className='button-chip'
            />
        </Box>
    );
    const slider = (
        <Box
            className='token-selector-form-container'
            display={'flex'}
            justifyContent='space-between'
            alignItems={'center'}
            flexWrap='nowrap'
        >
            <CustomizedSlider
                value={epochAmount}
                onChange={(value: number) => setEpochAmount(value)}
                min={1} // Minimum lock period
                max={100} // Maximum lock period (4 years)
            />
        </Box>
    );
    const epochAmountForm = (
        <Box my={'32px'}>
            <Typography variant="body1" className="label" mb={'8px'}>No. of Epochs to Bribe</Typography>
            <Typography variant="h3" mb={'16px'}>{epochAmount} epochs</Typography>
            {slider}
            {epochAmountPresets}
        </Box>
    );

    // NOTE: Mock data, assuming 1 epoch is 1 week (7 days)
    const distributionFromDate = moment(new Date()).format('D MMM YYYY');
    const distributionToDate = moment(new Date()).add(epochAmount * 7, 'days').format('D MMM YYYY');
    const rewardsDistributionLengthSection = (
        <Box
            display={'flex'}
            justifyContent={"space-between"}
            alignItems='flex-start'
            marginY={1}
        >
            <Typography className='label'>Distribution Period</Typography>
            <Box textAlign={'right'}>
                <Typography>
                    <Typography
                        variant="body2"
                        component={'span'}
                        mr='8px'
                    >From</Typography> 
                    {distributionFromDate}
                </Typography>
                <Typography>
                    <Typography
                        variant="body2"
                        component={'span'}
                        mr='8px'
                    >To</Typography>
                    {distributionToDate}
                </Typography>
            </Box>
            
        </Box>
    );

    const rewardsPerEpochSection = (
        <Box
            display={'flex'}
            justifyContent={"space-between"}
            alignItems='center'
            marginY={1}
        >
            <Typography className='label'>Rewards per Epoch</Typography>
            <Typography>1.5 DEXTER</Typography>
        </Box>
    );

    const additionalDetailsContent = (
        <Box>
            {rewardsDistributionLengthSection}
            {rewardsPerEpochSection}
        </Box>
    );

    const buttonToDisplay = <WalletGuard element={bribeButton} />

    const gasFeeSection = (
        <Box
            mt={'8px'}
            display='flex'
            justifyContent={'center'}
            alignItems='center'
        >
            <Typography className="label" mr={'8px'}>Gas Fee:</Typography>
            <Typography>0.34 LUNA</Typography>
        </Box>
    );

    const formButton = (
        <Box mt='32px'>
            {buttonToDisplay}
            {gasFeeSection}
        </Box>
    );

    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            title="Bribe Pool"
        >
            {selectedPoolSection}
            {bribeAmountForm}
            {epochAmountForm}
            {additionalDetailsContent}
            {formButton}
        </Modal>
    );
};