import { contractQuery } from "./utils";
import { ConnectedWallet } from "@terra-money/wallet-provider";
import { useQuery } from "react-query";
import { QUERY_KEY } from "queries";
import { DEXTER_VE_STAKE, getContractAddress } from "utils/getContractAddress";

export const useVeTokenQuery = (wallet?: ConnectedWallet) => {
    return useQuery(
        [QUERY_KEY.VE_AMOUNT, wallet],
        async () => {
            if (!wallet) {
                console.log("wallet not found");
                return Promise.resolve("0");
            }
            const factoryContract = getContractAddress(
                wallet.network.chainID,
                DEXTER_VE_STAKE
            );
            const res = await contractQuery<
                {
                    member: {
                        addr: string;
                    };
                },
                {
                    weight: string;
                }
            >(wallet.network, factoryContract, {
                member: {
                    addr: wallet.walletAddress,
                },
            }).catch(() => {
                return {
                    weight: "0",
                };
            });
            return res?.weight ?? "0";
        },
        {
            retry: false,
        }
    );
};
