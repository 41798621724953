import { useState, BaseSyntheticEvent, ReactElement } from 'react';
import {
  useConnectedWallet,
  useWallet,
  WalletStatus
} from '@terra-money/wallet-provider';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider
} from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import classNames from 'classnames';
import { isUndefined } from 'lodash';

import { truncateAddress } from 'utils';
import { useFetchTokenBalance } from 'queries';
import { LUNA } from 'types';
import { useCopy } from 'hooks';
import { UIElementProps } from 'components/UIElementProps';
import { ConnectWalletModal } from './ConnectWalletModal';
import { ReactComponent as WalletIcon } from '../../assets/icons/icon-wallet.svg';
import { beautifyAmount } from 'utils/amountFormatter';

interface NotConnectedButtonProps {
  onClick: () => void;
  type?: 'default' | 'primary' | 'secondary';
}

const NotConnectedButton = (props: NotConnectedButtonProps) => {
  const { onClick, type } = props;

  const { status } = useWallet();
  const walletIsInitializing = status === WalletStatus.INITIALIZING;

  const isDefaultButton = isUndefined(type) || type === 'default';
  const isPrimaryButton = type === 'primary';

  const buttonClassNames = classNames({
    'button-connect-wallet': isDefaultButton,
    'button-primary gradient': type === 'primary',
    'button-primary blue': type === 'secondary'
  }, 'button-base');

  const buttonIcon = isDefaultButton && <WalletIcon />;
  const buttonTextVariant = isPrimaryButton ? 'button' : 'body1';

  return (
    <Button
        variant='contained'
        className={buttonClassNames}
        startIcon={buttonIcon}
        disabled={walletIsInitializing}
        onClick={onClick}
        fullWidth={isPrimaryButton}
    >
      <Typography variant={buttonTextVariant}>
        {walletIsInitializing ? 'Loading' : 'Connect Wallet'}
      </Typography>
    </Button>
  );
};

interface ConnectedButtonProps {
  address: string;
  menuAnchorElement: HTMLElement | null;
  openMenu: boolean;
  onOpenMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseMenu: () => void;
  disconnectWallet: () => void;
}

const ConnectedButton = (props: ConnectedButtonProps) => {
  const {
    address,
    menuAnchorElement,
    openMenu,
    onOpenMenu,
    onCloseMenu,
    disconnectWallet
  } = props;

  const balance = useFetchTokenBalance(
    address,
    LUNA
  );
  const beautifiedBalance = balance && beautifyAmount(balance);

  const connectedButtonContent = (
    <Box display={'flex'}>
      <Typography variant='body1' className='vertical-divider' pr={'8px'}>{truncateAddress(address)}</Typography>
      <Typography variant='body1' pl={'8px'}>{LUNA.name} {beautifiedBalance}</Typography>
    </Box>
  );

  const connectedButton = (
    <Button
      variant='outlined'
      className='button-base button-connected-wallet'
      onClick={onOpenMenu}
      id='connected-wallet-button'
      aria-controls={openMenu ? 'connected-wallet-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={openMenu ? 'true' : undefined}
    >
      {connectedButtonContent}
    </Button>
  );

  const copyAddress = useCopy('address', address);
  const onCopyAddress = () => {
    copyAddress();
    onCloseMenu();
  };

  const menuOptions = (
    <Menu
      id="connected-wallet-menu"
      anchorEl={menuAnchorElement}
      open={openMenu}
      onClose={onCloseMenu}
      MenuListProps={{
        'aria-labelledby': 'connected-wallet-button',
      }}
      PaperProps={{
        className: 'dropdown-connected-wallet'
      }}
    >
      <MenuItem onClick={onCopyAddress} className='dropdown-connected-wallet-menu-item'>
        <ListItemIcon>
          <ContentCopyRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant='body1'>{truncateAddress(address, false)}</Typography>
      </MenuItem>
      <MenuItem
        component='a'
        href={`https://finder.terra.money/mainnet/address/${address}`}
        target={'_blank'}
        className='dropdown-connected-wallet-menu-item'
      >
        <ListItemIcon>
          <OpenInNewRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant='body1'>Terra Finder</Typography>
      </MenuItem>
      <Divider />
      <MenuItem onClick={disconnectWallet} className='dropdown-connected-wallet-menu-item'>
        <ListItemIcon>
          <ExitToAppRoundedIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant='body1'>Disconnect</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Box>
      {connectedButton}
      {menuOptions}
    </Box>
  );
};

interface WalletWidgetProps extends UIElementProps {
  type?: 'default' | 'primary' | 'secondary'
};

export const WalletWidget = (props: WalletWidgetProps) => {
  const { type } = props;

  const [openModal, setOpenModal] = useState(false);
  const [menuAnchorElement, setMenuAnchorElement] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchorElement);

  const onOpenMenu = (event: BaseSyntheticEvent) => {
    setMenuAnchorElement(event.currentTarget);
  };
  const onCloseMenu = () => {
    setMenuAnchorElement(null);
  };

  const { disconnect } = useWallet();
  const connectedWallet = useConnectedWallet();
  const walletAddress = connectedWallet?.walletAddress;
  const walletIsConnected =
    !isUndefined(walletAddress) || 
    !isUndefined(connectedWallet);

  const disconnectWallet = () => {
    onCloseMenu();
    disconnect();
  };

  const notConnectedButton = (
    <NotConnectedButton
      onClick={() => setOpenModal(true)}
      type={type}
    />
  );

  const connectedButton = (
    <ConnectedButton
      address={walletAddress as string}
      menuAnchorElement={menuAnchorElement}
      openMenu={openMenu}
      onOpenMenu={(event: BaseSyntheticEvent) => onOpenMenu(event)}
      onCloseMenu={() => onCloseMenu()}
      disconnectWallet={disconnectWallet}
    />
  );

  const buttonToDisplay = walletIsConnected ? connectedButton : notConnectedButton;

  return (
    <>
      {buttonToDisplay}
      <ConnectWalletModal open={openModal} onClose={() => setOpenModal(false)} />
    </>
  );
};

interface WalletGuardProps extends UIElementProps {
  element: ReactElement
};

export const WalletGuard = (props: WalletGuardProps) => {
  const { element } = props;

  const connectedWallet = useConnectedWallet();
  const walletIsConnected = !isUndefined(connectedWallet);

  return walletIsConnected ? element : <WalletWidget type='primary' />;
};
