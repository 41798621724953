import { Box, Typography } from "@mui/material";

import { VestingList } from "./VestingList/VestingList";
import { RewardsList } from "./RewardsList/RewardsList";

export const Vest = () => {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
      width="100%"
    >
      <Typography variant="h1" mb={"48px"}>
        Vesting
      </Typography>
      <RewardsList />
      <VestingList />
    </Box>
  );
};
