import { useState } from "react";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { Box, Typography, Button, Link } from "@mui/material";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { isUndefined } from "lodash";

import { usePoolsQuery } from "queries";
import { Card, TextSkeleton, SearchBar, Table } from "components";
import { GeneratePoolTableColumns, GeneratePoolTableRows } from "./helper";
import { ManagePoolModal } from "../ManagePoolModal/ManagePoolModal";
import { CreatePoolModal } from "../CreatePoolModal/CreatePoolModal";

export const PoolsList = () => {
    const [poolSearchInput, setPoolSearchInput] = useState<string>("");
    const [selectedPool, setSelectedPool] = useState<any>({});
    const [openManagePoolModal, setOpenManagePoolModal] =
        useState<boolean>(false);
    const [openCreatePoolModal, setOpenCreatePoolModal] =
        useState<boolean>(false);

    // Check wallet connection
    const connectedWallet = useConnectedWallet();
    const walletAddress = connectedWallet?.walletAddress;
    const walletIsConnected =
    !isUndefined(walletAddress) || !isUndefined(connectedWallet);

    const { isLoading: isPoolsLoading, data: pools } =
        usePoolsQuery(connectedWallet);

    const onOpenManagePoolModal = (selectedPoolData: any) => {
        setOpenManagePoolModal(true);
        setSelectedPool(selectedPoolData);
    };

    const filterGroup = (
        <Box display={"flex"} alignItems="center">
            <SearchBar
                value={poolSearchInput}
                onChange={setPoolSearchInput}
                customStyles={{ marginRight: "24px" }}
            />
            <Button
                variant="contained"
                className="button-base button-circle grey"
                onClick={() => {}}
            >
                <FilterListRoundedIcon />
            </Button>
        </Box>
    );

    const noResultsText = walletIsConnected ? 'No results.' : 'Please connect your wallet.';
    const poolTable = (
        <Table
            rows={GeneratePoolTableRows(pools ?? [], poolSearchInput)}
            columns={GeneratePoolTableColumns(onOpenManagePoolModal)}
            noResultsText={noResultsText}
        />
    );

    const createPoolButton = walletIsConnected && (
        <Typography mt={"24px"}>
            Can't find your ideal pool? Create your own{" "}
            <Link
                className="link-text"
                onClick={() => setOpenCreatePoolModal(true)}
            >
                here
            </Link>
            .
        </Typography>
    );

    const managePoolModal = (
        <ManagePoolModal
            open={openManagePoolModal}
            onClose={() => setOpenManagePoolModal(false)}
            selectedPool={selectedPool}
            wallet={connectedWallet}
        />
    );

    const createPoolModal = (
        <CreatePoolModal
            open={openCreatePoolModal}
            onClose={() => setOpenCreatePoolModal(false)}
        />
    );

    return (
        <Card width="100%" title="Pools">
            {filterGroup}
            {poolTable}
            {createPoolButton}
            {managePoolModal}
            {createPoolModal}
        </Card>
    );
};
