import { Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  useChainOptions,
  NetworkInfo,
  ReadonlyWalletSession,
  WalletProvider,
} from "@terra-money/wallet-provider";
import { Container, Grid } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useRefCallback, useBreakpoint } from "hooks";

import { ModalContainer, ModalProvider } from "libs/dialog";
import { TransactionsProvider } from "libs/transactions";

import { useReadOnlyWalletDialog } from "components/dialog/readonly-wallet";
import { NetworkGuard } from "components/network-guard";
import { SnackbarContainer } from "components/snackbar";

import { Header, Footer, ParticleBackground } from "components";
import { Swap, Pools, Vest, Bribe, Vote } from "pages";
import { useTransactionSnackbars } from "hooks/useTransactionSnackbars";

const queryClient = new QueryClient();

const MainPageLayout = () => {
  useTransactionSnackbars();
  const isTablet = useBreakpoint("tablet");

  const routes = (
    <Grid container justifyContent={"center"} alignItems="center" my={"48px"}>
      <Routes>
        <Route path="/" element={<Swap />} />
        <Route path="/pools" element={<Pools />} />
        <Route path="/vest" element={<Vest />} />
        <Route path="/vote" element={<Vote />} />
        <Route path="/bribe" element={<Bribe />} />
      </Routes>
    </Grid>
  );

  const footer = !isTablet && <Footer />;

  return (
    <>
      <Container className="app-body-container">
        <NetworkGuard>
          <Header />
          {routes}
        </NetworkGuard>
      </Container>
      {footer}
    </>
  );
};

const Content = () => {
  const chainOptions = useChainOptions();
  const openReadOnlyWalletDialog = useReadOnlyWalletDialog();

  const createReadOnlyWalletSession = useRefCallback(
    (networks: NetworkInfo[]): Promise<ReadonlyWalletSession> => {
      return openReadOnlyWalletDialog({
        networks,
      }) as Promise<ReadonlyWalletSession>;
    },
    [openReadOnlyWalletDialog]
  );

  // TODO: check later if chainOptions would cause a flicker due to being null for first couple of calls
  return (
    chainOptions && (
      <WalletProvider
        connectorOpts={{ bridge: "https://walletconnect.terra.dev/" }}
        createReadonlyWalletSession={createReadOnlyWalletSession}
        defaultNetwork={chainOptions.defaultNetwork}
        walletConnectChainIds={chainOptions.walletConnectChainIds}
      >
        <TransactionsProvider>
          <SnackbarProvider
            autoHideDuration={null}
            content={(key: any, message: any) => (
              <SnackbarContainer id={key} message={message} />
            )}
          >
            <MainPageLayout />
            <ParticleBackground />

            {/* TODO: Check if we're using this ModalContainer, if not we can delete */}
            <ModalContainer />
          </SnackbarProvider>
        </TransactionsProvider>
      </WalletProvider>
    )
  );
};

const App = () => {
  return (
    <ModalProvider>
      <QueryClientProvider client={queryClient}>
        <Content />
      </QueryClientProvider>
    </ModalProvider>
  );
};

export default App;
