import { useState } from "react";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { Box, Button, Typography } from "@mui/material";
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { isUndefined } from "lodash";

import { Card, Table, SearchBar } from "components";
import { GenerateVestingListTableColumns, GenerateVestingListTableRows } from "./helper";

export const VestingList = () => {
    // Check wallet connection
    const connectedWallet = useConnectedWallet();
    const walletAddress = connectedWallet?.walletAddress;
    const walletIsConnected =
    !isUndefined(walletAddress) || !isUndefined(connectedWallet);

    const noResultsText = walletIsConnected ? 'No results.' : 'Please connect your wallet.';
    const vestingListTable = (
        <Table
            rows={GenerateVestingListTableRows(null)} // TODO: Send real data instead of null once we have real data
            columns={GenerateVestingListTableColumns()}
            noResultsText={noResultsText}
        />
    );

    const claimAllButton = walletIsConnected ? (
        <Button
          variant="contained"
          className="button-base button-primary gradient"
          onClick={() => {}}
        >
          <Typography>Claim All</Typography>
        </Button>
    ) : undefined;

    return (
        <>
            <Card
                width="100%"
                title="Vesting"
                actionButton={claimAllButton}
            >
                {vestingListTable}
            </Card>
        </>
    );
};