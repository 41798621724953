import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from 'react';

interface ModalContextValue {
  pushModal: (modal: ReactNode) => void;
  popModal: () => void;
  modals: ReactNode[];
  popAllModals: () => void;
}

const ModalContext = createContext<ModalContextValue | undefined>(undefined);

const useModalContext = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw Error('The ModalContext has not been defined.');
  }
  return context;
};

interface ModalProviderProps {
  children: ReactNode;
}

const ModalProvider = (props: ModalProviderProps) => {
  const { children } = props;

  const [modals, setModals] = useState<ReactNode[]>([]);

  const pushModal = useCallback(
    (modal: ReactNode) => {
      setModals((previous) => [...previous, modal]);
    },
    [setModals]
  );

  const popModal = useCallback(() => {
    setModals((previous) => previous.slice(0, previous.length - 1));
  }, [setModals]);

  const popAllModals = useCallback(() => {
    setModals([]);
  }, [setModals]);

  const value = useMemo(() => {
    return {
      pushModal,
      popModal,
      modals,
      popAllModals,
    };
  }, [pushModal, popModal, modals, popAllModals]);

  return (
    <ModalContext.Provider value={value}>
      <>{children}</>
    </ModalContext.Provider>
  );
};

export { ModalProvider, useModalContext };
