import { useConnectedWallet, UserDenied } from '@terra-money/wallet-provider';
import { Box, Typography, Link } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useSnackbar } from 'notistack';

import { Snackbar } from 'components';
import {
  CompletedTransaction,
  FailedTransaction,
  PendingTransaction,
  useTransactionSubscribers,
} from 'libs/transactions';
import { useRefCallback } from 'hooks';
import { finderTxUrl } from 'utils';

const getErrorText = (error: FailedTransaction['error']) => {
  // TODO: Provide better error message
  if (error instanceof UserDenied) {
    return error.message;
  }

  return error.message;
};

export const useTransactionSnackbars = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const connectedWallet = useConnectedWallet();

  const onPending = useRefCallback(
    (transaction: PendingTransaction) => {
      enqueueSnackbar(<Snackbar variant='neutral' text={'Transaction pending'} />, {
        preventDuplicate: true,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        autoHideDuration: 5000,
        key: transaction.txHash
      });
    },
    [enqueueSnackbar]
  );

  const onCancelled = useRefCallback(
    (transaction: PendingTransaction) => {
      closeSnackbar(transaction.txHash);
    },
    [closeSnackbar]
  );

  const onCompleted = useRefCallback(
    (transaction: CompletedTransaction) => {
      closeSnackbar(transaction.txHash);
      
      const explorerUrl = finderTxUrl(connectedWallet?.network.name as string, transaction.txHash);
      const linkToExplorer = (
        <Link href={explorerUrl} target='_blank'>
          <Box display='flex' alignItems={'center'}>
            <Typography mr={1}>View details</Typography>
            <LaunchIcon fontSize='inherit' />
          </Box>
        </Link>
      );

      enqueueSnackbar(
        <Snackbar
          variant='success'
          text={'Transaction completed'}
          children={linkToExplorer}
        />,
        {
          preventDuplicate: true,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          autoHideDuration: 5000
        }
      );
    },
    [enqueueSnackbar, closeSnackbar]
  );

  const onFailed = useRefCallback(
    (transaction: FailedTransaction) => {
      closeSnackbar(transaction.txHash);

      const errorText = getErrorText(transaction.error);
      const errorMessageToDisplay = (
        <Typography mr={1}>{errorText}</Typography>
      );

      enqueueSnackbar(
        <Snackbar
          variant='error'
          text={'Transaction failed'}
          children={errorMessageToDisplay}
        />,
        {
          preventDuplicate: true,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          autoHideDuration: 5000
        }
      );
    },
    [enqueueSnackbar, closeSnackbar]
  );

  useTransactionSubscribers({
    onPending,
    onCancelled,
    onCompleted,
    onFailed,
  });
};
