import { ConnectedWallet, useLCDClient } from "@terra-money/wallet-provider";
import { useQuery } from "react-query";
import { QUERY_KEY } from "queries";
import {
    CreateTxOptions,
    Fee,
    Numeric,
    SignerData,
    Tx,
    LCDClient,
    AuthInfo,
    SimulateResponse,
    Coins,
    TxBody,
    Dec,
} from "@terra-money/terra.js";
import _ from "lodash";

export type SimulateWithFeeResponse = SimulateResponse & {
    fee: Fee;
};

export const useEstimateFeeQuery = (
    wallet?: ConnectedWallet,
    tx?: CreateTxOptions
) => {
    const lcd = useLCDClient();
    return useQuery([QUERY_KEY.ESTIMATE_FEE, wallet, tx], async () => {
        if (!wallet || !tx || Object.keys(tx).length === 0) {
            return new Fee(0, "0 LUNA");
        }
        console.log("Trying to find fees", JSON.stringify(tx));
        const account = await lcd.auth.accountInfo(wallet.walletAddress);

        const fee = await lcd.tx.estimateFee(
            [
                {
                    publicKey: account.getPublicKey(),
                    sequenceNumber: account.getSequenceNumber(),
                },
            ],
            tx
        );
        return fee;
    });
};

export const useSimulateTxQuery = (
    wallet?: ConnectedWallet,
    txOption?: CreateTxOptions
) => {
    const lcd = useLCDClient();
    return useQuery([QUERY_KEY.ESTIMATE_FEE, wallet, txOption], async () => {
        if (!wallet || !txOption || Object.keys(txOption).length === 0) {
            return {
                fee: new Fee(0, "0uluna"),
            } as SimulateWithFeeResponse;
        }
        const gasAdjustment = txOption.gasAdjustment ?? "1.2";
        const gasPrices =
            txOption.gasPrices ?? lcd.config.gasPrices ?? "0uluna";
        const gasPriceCoins = new Coins(gasPrices);
        const account = await lcd.auth.accountInfo(wallet.walletAddress);
        const txBody = new TxBody(txOption.msgs, txOption.memo || "");
        const authInfo = new AuthInfo([], new Fee(0, new Coins()));
        const tx = new Tx(txBody, authInfo, []);

        tx.appendEmptySignatures([
            {
                publicKey: account.getPublicKey(),
                sequenceNumber: account.getSequenceNumber(),
            },
        ]);
        const simulateRes = await simulateTx(lcd, tx, {});
        const gasLimit = new Dec(gasAdjustment)
            .mul(simulateRes.gas_info.gas_used)
            .toNumber();
        const gasLuna =
            gasPriceCoins
                .mul(gasLimit)
                .toIntCeilCoins()
                .get("uluna")
                ?.toString() ?? "0uluna";
        return {
            ...simulateRes,
            fee: new Fee(gasLimit, gasLuna),
        } as SimulateWithFeeResponse;
    });
};

const encode = (tx: Tx): string => {
    return Buffer.from(tx.toBytes()).toString("base64");
};

const simulateTx = async (
    lcd: LCDClient,
    tx: Tx,
    options?: {
        signers?: SignerData[];
    }
): Promise<SimulateResponse> => {
    // append empty signatures if there's no signatures in tx
    let simTx: Tx = tx;
    if (tx.signatures.length <= 0) {
        if (!(options && options.signers && options.signers.length > 0)) {
            throw Error("cannot append signature");
        }
        const authInfo = new AuthInfo([], new Fee(0, new Coins()));
        simTx = new Tx(tx.body, authInfo, []);
        simTx.appendEmptySignatures(options.signers);
    }

    const simulateRes = await lcd.apiRequester
        .post<SimulateResponse.Data>(`/cosmos/tx/v1beta1/simulate`, {
            tx_bytes: encode(simTx),
        })
        .then((d) => SimulateResponse.fromData(d));
    return simulateRes;
};

export const searchEvents = (
    events: {
        type: string;
        attributes: {
            key: string;
            value: string;
        }[];
    }[],
    type: string,
    key: string
) => {
    const encodedKey = Buffer.from(key).toString("base64");
    for (const event of events) {
        if (event.type === type) {
            for (const attr of event.attributes) {
                if (attr.key === encodedKey) {
                    return Buffer.from(attr.value, "base64").toString();
                }
            }
        }
    }
};

export const searchContractEvents = (
    events: {
        type: string;
        attributes: {
            key: string;
            value: string;
        }[];
    }[],
    contract: string,
    key: string
) => {
    const encodedKey = Buffer.from(key).toString("base64");
    const encodedContractKey =
        Buffer.from("_contract_address").toString("base64");
    // NOTE: Check for contract availability, sometimes the contract is undefined
    const encodedContract = contract && Buffer.from(contract).toString("base64");
    let isInterestedContract = false;
    for (const event of events) {
        if (event.type === "wasm") {
            for (const attr of event.attributes) {
                if (attr.key === encodedContractKey) {
                    isInterestedContract = attr.value === encodedContract;
                }
                if (attr.key === encodedKey && isInterestedContract) {
                    return Buffer.from(attr.value, "base64").toString();
                }
            }
        } else {
            isInterestedContract = false;
        }
    }
};
