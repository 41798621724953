import Big from 'big.js';
import { u, Token } from 'types/types';

// NOTE: Helper function to beautify amount format
// F.ex. 1000500.50 will be formatted into 1,000,500.50
export const beautifyAmount = (amount: string | number) => {
    const amountIsString = typeof amount === "string";
    const amountToBeFormatted = amountIsString ? parseFloat(amount) : amount;

    return amountToBeFormatted.toLocaleString("en-US");
};

export const beautifyDecimalAmount = (
    amount: string | number,
    decimals: number
) => {
    const amountIsString = typeof amount === "string";
    const amountToBeFormatted = amountIsString ? parseFloat(amount) : amount;
    return beautifyAmount(amountToBeFormatted / Math.pow(10, decimals));
};

export const beautifyAmountByToken = (balance: u<Big>| string | undefined, token?: Token) => {
    let formattedBalance;
    if (token) {
        const decimalDivider = Number(`1e${token.decimals}`);
        const dividedBalance = balance && Big(balance).toNumber() / decimalDivider;
        formattedBalance = dividedBalance?.toString();
    } else {
        formattedBalance = balance && Big(balance).toString();
    }
  
    return formattedBalance;
};

export const microAmount = (amount: string | number, decimals: number) => {
    const amountInString = amount.toString();
    let [whole, decimal] = amountInString.split(".");
    if (decimal === undefined) {
        decimal = "".padEnd(decimals, "0");
    } else if (decimal.length > decimals) {
        decimal = decimal.slice(0, decimals);
    } else {
        decimal = decimal.padEnd(decimals, "0");
    }
    return whole + decimal;
};
