import { useState, useMemo } from "react";
import { Box, Typography, Button, Grid, Chip } from "@mui/material";

import { generatePoolCompositionCell } from "utils/tableCellDataGenerator";
import {
    Modal,
    WalletGuard,
    TextSkeleton,
    CustomizedSlider
} from "components";
import classNames from "classnames";

interface VoteModalProps {
    open: boolean;
    onClose: () => void;
    selectedPool: any;
    votePool: (selectedPoolAddress: string, weight: number) => void;
    voteWeights: { [key: string]: number };
    votingPowerUsed: number;
}

export const VoteModal = (props: VoteModalProps) => {
    const {
        open,
        onClose,
        selectedPool,
        votePool,
        voteWeights,
        votingPowerUsed
    } = props;

    const tokenOptions = selectedPool?.poolComposition;
    const gaugeAddress = selectedPool?.id;
    const poolName = tokenOptions && generatePoolCompositionCell(tokenOptions);
    const presetVoteWeight = voteWeights[gaugeAddress];
    const votingPowerLeft = 100 - votingPowerUsed;

    const [weight, setWeight] = useState<number>(0);
    
    useMemo(() => {
        setWeight(presetVoteWeight || 0);
    }, [presetVoteWeight]);

    const inputIsValid = weight > 0;

    const selectedPoolSection = (
        <Box mb={'32px'}>
            <Typography className="label" mb={'8px'}>Pool</Typography>
            {poolName}
        </Box>
    );

    const votingPowerLeftSection = (
        <Box mb={'32px'}>
            <Typography className="label" mb={'8px'}>Voting Power Left</Typography>
            <Typography>{votingPowerLeft} %</Typography>
        </Box>
    );

    const voteWeightPresets = (
        <Box
            display={'flex'}
            justifyContent='flex-end'
            alignItems={'center'}
            mt='8px'
        >
            <Chip
                label="MAX"
                data-value={votingPowerLeft}
                onClick={() => setWeight(votingPowerLeft)}
                className='button-chip'
            />
        </Box>
    );

    const invalidWeight = weight > votingPowerLeft;
    const weightFormContainerClassNames = classNames(
        {
            invalid: invalidWeight,
        },
        "token-selector-form-container"
    );

    const voteWeightSlider = (
        <Grid
            className={weightFormContainerClassNames}
            container
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
            flexWrap="nowrap"
        >
            <Grid item xs={6} md={8} display="flex" justifyContent={"flex-start"}>
                <CustomizedSlider
                    value={weight}
                    onChange={(value: number) => setWeight(value)}
                    min={0} // Minimum weight
                    max={100} // Maximum weight
                />
            </Grid>
            <Grid
                item
                xs={6}
                md={4}
                display="flex"
                flexDirection={"column"}
                alignItems="flex-end"
            >
                <Typography variant="h3">{weight} %</Typography>
            </Grid>
        </Grid>
    );

    const voteWeightForm = (
        <Box>
            <Typography className="label" mb={'8px'}>Vote Weight</Typography>
            {voteWeightSlider}
            {voteWeightPresets}
        </Box>
    );

    const voteButton = (
        <Button
            variant="contained"
            className="button-base button-primary gradient"
            disabled={!inputIsValid || invalidWeight} // TODO: Disable this button if user has no veDXTR
            fullWidth
            onClick={() => votePool(gaugeAddress, weight)}
        >
            <Typography variant="button">Confirm Vote</Typography>
        </Button>
    );

    const buttonToDisplay = <WalletGuard element={voteButton} />

    const formButton = (
        <Box mt='32px'>
            {buttonToDisplay}
        </Box>
    );

    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            title="Gauge Vote"
        >
            {selectedPoolSection}
            {votingPowerLeftSection}
            {voteWeightForm}
            {formButton}
        </Modal>
    );
};