import { ReactElement } from "react";
import { Box, Typography } from "@mui/material";
import classNames from "classnames";

import { useBreakpoint } from "hooks";
import { UIElementProps } from "components/UIElementProps";

interface CardProps extends UIElementProps {
    width?: string;
    title?: string;
    subtitle?: string;
    customClassName?: string;
    customPadding?: string;
    actionButton?: ReactElement | undefined
}

export const Card = (props: CardProps) => {
    const {
        width,
        title,
        subtitle,
        customClassName,
        customPadding,
        actionButton,
        children
    } = props;

    const isMobile = useBreakpoint('mobile');

    const cardClassNames = classNames({
        'w-550': !width // Default width
    }, `card-container ${customClassName}`);

    const titleText = title && (
        <Typography
            variant="h4"
            textAlign={'left'}
        >
            {title}
        </Typography>
    );

    const subtitleText = subtitle && (
        <Typography
            textAlign={'left'}
            mt='-24px'
            mb='36px'
        >
            {subtitle}
        </Typography>
    );

    const showTitleSection = title || actionButton;
    const titleSection = showTitleSection && (
        <Box
            display={'flex'}
            justifyContent='space-between'
            alignItems={'center'}
            mb='36px'
        >
            {titleText}
            {actionButton}
        </Box>
    );

    return (
        <Box className={cardClassNames} width={width}>
            <Box p={isMobile ? '16px' : customPadding || '48px'}>
                {titleSection}
                {subtitleText}
                {children}
            </Box>
        </Box>
    );
};