import { TxBuilder, useTx } from "libs/transactions";
import { DEXTER_ROUTER, getContractAddress } from "../utils/getContractAddress";
import { ConnectedWallet } from "@terra-money/wallet-provider";
import { Token, TokenToAssetInfo } from "types";

type SwapTransactionProps = {
  wallet: ConnectedWallet;
  offerToken: Token;
  askToken: Token;
  offerAmount: string;
  slippage: string;
};

export const BuildSwapMsg = (
  wallet: ConnectedWallet,
  offerToken: Token,
  offerAmount: string,
  askToken: Token,
  spread: string
) => {
  const routerContract = getContractAddress(
    wallet.network.chainID,
    DEXTER_ROUTER
  );
  if (offerToken.type === "cw20") {
    return TxBuilder.new()
      .execute(wallet.walletAddress, offerToken.token, {
        send: {
          contract: routerContract,
          amount: offerAmount,
          msg: Buffer.from(
            JSON.stringify({
              execute_swap_operations: {
                operations: [
                  {
                    swap: {
                      offer_asset_info: TokenToAssetInfo(offerToken),
                      ask_asset_info: TokenToAssetInfo(askToken),
                    },
                  },
                ],
                minimum_receive: null,
                to: wallet.walletAddress,
                max_spread: spread,
              },
            })
          ),
        },
      })
      .build();
  } else {
    return TxBuilder.new()
      .execute(
        wallet.walletAddress,
        routerContract,
        {
          execute_swap_operations: {
            operations: [
              {
                swap: {
                  offer_asset_info: TokenToAssetInfo(offerToken),
                  ask_asset_info: TokenToAssetInfo(askToken),
                },
              },
            ],
            minimum_receive: null,
            to: wallet.walletAddress,
            max_spread: spread,
          },
        },
        {
          [offerToken.denom]: offerAmount,
        }
      )
      .build();
  }
};
