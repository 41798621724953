import { Coins } from "@terra-money/terra.js";
import { ConnectedWallet } from "@terra-money/wallet-provider";
import { TxBuilder } from "libs/transactions";
import { Token, TokenToAssetInfo } from "types";
import {
    DEXTER_GAUGE_CONTROLLER,
    getContractAddress,
} from "utils/getContractAddress";

// Deposit tokens into a pool
// To get the pair contract, use the usePoolQuery with the tokens
export const BuildAddLiqudityMsg = (
    wallet: ConnectedWallet,
    pairContract: string,
    token0: Token,
    token0Amount: string,
    token1: Token,
    token1Amount: string,
    slippage_tolerance_pc: string
) => {
    let slippage_tolerance = (
        Number.parseFloat(slippage_tolerance_pc) / 100
    ).toString();
    let coins = {} as any;
    let txBuilder = TxBuilder.new();
    if (token0.type === "native") {
        coins[token0.denom] = token0Amount;
    } else if (token0.type === "cw20") {
        txBuilder = txBuilder.execute(wallet.walletAddress, token0.token, {
            increase_allowance: {
                spender: pairContract,
                amount: token0Amount,
            },
        });
    }
    if (token1.type === "native") {
        coins[token1.denom] = token1Amount;
    } else if (token1.type === "cw20") {
        txBuilder = txBuilder.execute(wallet.walletAddress, token1.token, {
            increase_allowance: {
                spender: pairContract,
                amount: token1Amount,
            },
        });
    }

    return txBuilder
        .execute(
            wallet.walletAddress,
            pairContract,
            {
                provide_liquidity: {
                    assets: [
                        {
                            info: TokenToAssetInfo(token0),
                            amount: token0Amount,
                        },
                        {
                            info: TokenToAssetInfo(token1),
                            amount: token1Amount,
                        },
                    ],
                    slippage_tolerance: slippage_tolerance,
                },
            },
            coins
        )
        .build();
};

// Withdraw tokens from a pool
// To get the pair contract and pool token contract, use the usePoolQuery with the tokens
// liquidity_token = poolTokenContract
export const BuildWithdrawLiquidityMsg = (
    wallet: ConnectedWallet,
    poolTokenContract: string,
    pairContract: string,
    amount: string
) => {
    return TxBuilder.new()
        .execute(wallet.walletAddress, poolTokenContract, {
            send: {
                contract: pairContract,
                amount,
                msg: Buffer.from(
                    JSON.stringify({
                        withdraw_liquidity: {},
                    })
                ).toString("base64"),
            },
        })
        .build();
};

// Stake pool tokens into the gauge controller to get access to protocol emissions
export const BuildStakePoolTokenMsg = (
    wallet: ConnectedWallet,
    poolTokenContract: string,
    amount: string
) => {
    const contract = getContractAddress(
        wallet.network.chainID,
        DEXTER_GAUGE_CONTROLLER
    );
    return TxBuilder.new()
        .execute(wallet.walletAddress, poolTokenContract, {
            send: {
                contract,
                amount,
                msg: Buffer.from(
                    JSON.stringify({
                        stake_pool_tokens: {},
                    })
                ).toString("base64"),
            },
        })
        .build();
};

export const BuildUnstakePoolTokenMsg = (
    wallet: ConnectedWallet,
    poolTokenContract: string,
    amount: string
) => {
    const contract = getContractAddress(
        wallet.network.chainID,
        DEXTER_GAUGE_CONTROLLER
    );
    return TxBuilder.new()
        .execute(wallet.walletAddress, contract, {
            withdraw_pool_tokens: {
                token: poolTokenContract,
                amount,
            },
        })
        .build();
};
