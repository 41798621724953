import { useState } from "react";
import {
    Box,
    Typography,
    Button,
} from "@mui/material";
import { isUndefined } from "lodash";

import { Token } from "types";
import {
    Modal,
    TokenSelectorForm,
    TextSkeleton,
    WalletGuard
} from "components";

interface CreatePoolModalProps {
    open: boolean;
    onClose: () => void;
};

export const CreatePoolModal = (props: CreatePoolModalProps) => {
    const { open, onClose } = props;

    const [selectedFirstAsset, setSelectedFirstAsset] = useState<Token | undefined>();
    const [selectedSecondAsset, setSelectedSecondAsset] = useState<Token | undefined>();
    const inputsAreValid = !isUndefined(selectedFirstAsset) && !isUndefined(selectedSecondAsset);

    const firstTokenForm = (
        <Box>
            <TokenSelectorForm
                token={selectedFirstAsset}
                onSelectToken={setSelectedFirstAsset}
                withSelectorOnly
                label='Token 1'
                withAmountPreset={false}
            />
        </Box>
    );

    const secondTokenForm = (
        <Box mt='32px'>
            <TokenSelectorForm
                token={selectedSecondAsset}
                onSelectToken={setSelectedSecondAsset}
                withSelectorOnly
                label='Token 2'
                withAmountPreset={false}
            />
        </Box>
    );

    const createPoolButton = (
        <Button
            variant="contained"
            className="button-base button-primary gradient"
            disabled={!inputsAreValid}
            fullWidth
            onClick={() => {}}
        >
            <Typography variant="button">Create Pool</Typography>
        </Button>
    );

    const buttonToDisplay = <WalletGuard element={createPoolButton} />

    const gasFeeSection = (
        <Box
            mt={'8px'}
            display='flex'
            justifyContent={'center'}
            alignItems='center'
        >
            <Typography className="label" mr={'8px'}>Gas Fee:</Typography>
            <Typography>0.34 LUNA</Typography>
        </Box>
    );

    const formButton = (
        <Box mt='32px'>
            {buttonToDisplay}
            {gasFeeSection}
        </Box>
    );

    return (
        <Modal
            open={open}
            onClose={() => onClose()}
            title='Create Pool'
        >
            {firstTokenForm}
            {secondTokenForm}
            {formButton}
        </Modal>
    );
};