export function truncateAddress(
  address: string = '',
  noTail: Boolean = true,
  [headLength, tailLength]: [number, number] = [10, 5]
): string {
  const trimmedHead = address.slice(0, headLength);
  const trimmedTail = address.slice(-1 * tailLength, address.length);
  
  if (noTail) {
    return trimmedHead + '...';
  } else {
    return address.length > headLength + tailLength ?
      [trimmedHead, trimmedTail].join('...') : address;
  }
}
