import { useMemo } from "react";

export const useSearchPool = (
    mappedRows: any[], 
    searchInput?: string
) => useMemo(() => {
    if (mappedRows) {
        let filteredRowsToDisplay = mappedRows;

        if (searchInput) {
            const lowerCasedSearchInput = searchInput.toLowerCase();
            filteredRowsToDisplay = mappedRows.filter(row => {
                // NOTE: Currently only support searching by pool name
                const lowerCasedPoolName = row.poolComposition.name.toLowerCase();
                return lowerCasedPoolName.includes(lowerCasedSearchInput);
            });
        }

        return filteredRowsToDisplay;
    }
}, [mappedRows, searchInput]);