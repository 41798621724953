import { UIElementProps } from 'components/UIElementProps';
import classNames from 'classnames';
import styles from './Form.module.sass';

export const Form = (props: UIElementProps) => {
  const { children, className } = props;

  return (
    <div
      className={classNames(styles.root, className)}
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {children}
    </div>
  );
};
