import { Box, Typography } from "@mui/material";
import { DataGrid, GridColumns } from '@mui/x-data-grid';

interface TableProps {
    rows: object[];
    columns: GridColumns;
    noResultsText?: string;
};

export const Table = (props: TableProps) => {
    const { rows, columns, noResultsText } = props;

    const noResultsElement = (
        <Box
            display='flex'
            alignItems={'center'}
            justifyContent='center'
            width={'100%'}
            height={'100%'}
        >
            <Typography variant="body2">{noResultsText || 'No results.'}</Typography>
        </Box>
    );

    return (
        <Box mt='24px' sx={{overflowX: 'scroll'}}>
            <DataGrid
                rows={rows}
                columns={columns}
                className="table-base"
                pageSize={10}
                rowsPerPageOptions={[10]}
                isRowSelectable={() => false}
                rowHeight={68}
                disableColumnMenu
                autoHeight
                components={{
                    NoRowsOverlay: () => noResultsElement
                }}
            />
        </Box>
    );
};