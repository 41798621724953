import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import { ReactComponent as DexterLogo } from '../../assets/logos/logo-dexter.svg';

export const TextSkeleton = () => {
    return (
        <Typography component={'span'} className='text-skeleton' />
    );
};

interface SpinningLogoProps {
    size?: 'sm' | 'lg';
}

export const SpinningLogo = (props: SpinningLogoProps) => {
    const { size } = props;

    const logoClassNames = classNames({
        'sm': !size || size === 'sm',
        'lg': size === 'lg'
    }, 'spinning-logo');

    return (
        <Box display={'flex'} justifyContent='center'>
            <DexterLogo className={logoClassNames} />
        </Box>
    );
};