import { useEffect, useState, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";

import { LUNA, Token } from "types";
import {
    ClickableIcon,
    CustomizedTooltip,
    TokenSelectorForm,
    TextSkeleton,
    WalletGuard
} from "components";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/icon-settings.svg";
import { ReactComponent as TooltipIcon } from "../../../assets/icons/icon-tooltip.svg";
import {
    fetchTokenBalance,
    searchContractEvents,
    useSimulateTxQuery,
} from "queries";
import { CreateTxOptions } from "@terra-money/terra.js";
import { ConnectedWallet } from "@terra-money/wallet-provider";
import { BuildAddLiqudityMsg } from "../../../transactions/poolTransactions";
import { beautifyAmountByToken, beautifyDecimalAmount, microAmount } from "utils/amountFormatter";
import { useTx, UseTxProps } from "libs/transactions";

interface AddLiquidityProps {
    selectedPool: any;
    slippage: string;
    setOpenSlippageConfigModal: () => void;
    wallet?: ConnectedWallet;
}

export const AddLiquidity = (props: AddLiquidityProps) => {
    const { selectedPool, slippage, setOpenSlippageConfigModal, wallet } = props; 

    const tokenOptions = selectedPool?.poolComposition?.tokens;

    const [tokensAmount, setTokensAmount] = useState<{
        [key: string]: string;
    }>({});
    const [poolTokenReceived, setPoolTokenReceived] = useState<string>("0");
    const [poolTokenBalance, setPoolTokenBalance] = useState<string>('0');

    const walletIsHoldingPoolToken = parseFloat(poolTokenBalance) > 0;

    // Fetch gas fee
    const [potentialTransaction, setPotentialTransaction] = useState(
        {} as CreateTxOptions
    );
    const {
        isLoading: isLoadingEstimatedFee,
        data: simulateRes,
        error: estimatedFeeError,
    } = useSimulateTxQuery(wallet, potentialTransaction);

    // Fetch LP token balance
    useMemo(async () => {
        if (wallet) {
            const balance = await fetchTokenBalance({
                lcdUrl: wallet.network.lcd,
                chainId: wallet.network.chainID,
                walletAddr: wallet.walletAddress as string,
                tokenAddr: selectedPool.poolToken
            });
            setPoolTokenBalance(balance || '0');
        }
    }, [selectedPool]);

    useEffect(() => {
        if (wallet && Object.keys(tokensAmount).length === 2) {
            // Set 2s delay for:
            // - Let a query to finish before starting a new one
            // - UI will display the correct latest data (query overlapping causing wrong data to be displayed)
            // - Give time for user to finish inputting the amount before we start querying
            setTimeout(() => {
                const token0Amount = microAmount(
                    tokensAmount[tokenOptions[0].symbol],
                    tokenOptions[0].decimals
                );
                const token1Amount = microAmount(
                    tokensAmount[tokenOptions[1].symbol],
                    tokenOptions[1].decimals
                );
                let msg = BuildAddLiqudityMsg(
                    wallet,
                    selectedPool.id,
                    tokenOptions[0],
                    token0Amount,
                    tokenOptions[1],
                    token1Amount,
                    slippage
                );
                console.log(msg);
                setPotentialTransaction(msg);
            }, 2000);
        }
    }, [wallet, tokensAmount]);

    useEffect(() => {
        if (simulateRes && simulateRes.result) {
            const mintAmount = searchContractEvents(
                simulateRes.result.events,
                selectedPool.poolToken,
                "amount"
            );
            setPoolTokenReceived(mintAmount ?? "0");
        }
    }, [simulateRes]);

    const [swapTxResult, addLiquidityTx] = useTx<UseTxProps>(
        (props: UseTxProps) => {
            return {
                msgs: props.msgs,
            };
        },
        { txKey: "EXECUTE:SWAP" },
        {}
    );

    // Form validation
    // One of the token amount have to be filled and amount have to be > 0
    // TODO: Revisit how we should validate the form
    const inputsAreValid = Object.keys(tokensAmount).some((key) => {
        return tokensAmount[key] !== "" && parseFloat(tokensAmount[key]) > 0;
    });

    const tokenForms = () => {
        const mappedTokenForms = tokenOptions.map(
            (token: Token, index: number) => {
                return (
                    <Box mb={"32px"} key={index}>
                        <TokenSelectorForm
                            token={token}
                            disableSelector
                            amount={tokensAmount[token.symbol] || ""}
                            onChangeAmount={(amount: string) => {
                                setTokensAmount((prevState: any) => ({
                                    ...prevState,
                                    [token.symbol]: amount,
                                }));
                            }}
                            label={`Token ${index + 1}`}
                            withAmountPreset
                        />
                    </Box>
                );
            }
        );
        return mappedTokenForms;
    };

    const slippageSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Box display={"flex"} alignItems="center">
                <Typography className="label">Slippage</Typography>
                <ClickableIcon
                    variant="button"
                    icon={<SettingsIcon />}
                    onClick={setOpenSlippageConfigModal}
                    className="button-icon-base button-icon"
                />
                <CustomizedTooltip
                    triggerElement={<TooltipIcon />}
                    text="Yo this is a tooltip!"
                />
            </Box>
            <Typography>{slippage} %</Typography>
        </Box>
    );

    const shareOfPoolSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Typography className="label">Share of Pool</Typography>
            <Typography>0.02 %</Typography>
        </Box>
    );

    const tokensReceivedSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Typography className="label">LP Tokens Received</Typography>
            <Typography>
                {beautifyDecimalAmount(poolTokenReceived, 6)}
            </Typography>
        </Box>
    );

    const poolAprSection = (
        <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems="center"
            marginY={1}
        >
            <Typography className="label">Pool APR</Typography>
            <Typography>{selectedPool.apr} %</Typography>
        </Box>
    );

    const additionalDetailsContent = (
        <Box mt="32px">
            {slippageSection}
            {shareOfPoolSection}
            {tokensReceivedSection}
            {poolAprSection}
        </Box>
    );

    const stakeButton = walletIsHoldingPoolToken && (
        <Button
            variant="contained"
            className="button-base button-primary gradient"
            // disabled={!inputsAreValid}
            fullWidth
            onClick={() => {}}
            sx={{ mb: '16px' }}
        >
            <Typography variant="button">Stake LP</Typography>
        </Button>
    );

    const addLiquidityButton = (
        <Button
            variant="contained"
            className="button-base button-primary gradient"
            disabled={!inputsAreValid}
            fullWidth
            onClick={() =>
                addLiquidityTx({
                    msgs: potentialTransaction.msgs,
                    wallet: wallet!,
                    fee: simulateRes?.fee ?? "auto",
                })
            }
        >
            <Typography variant="button">Add Liquidity</Typography>
        </Button>
    );

    const buttonGroup = (
        <Box>
            {stakeButton}
            {addLiquidityButton}
        </Box>
    );

    const buttonToDisplay = <WalletGuard element={buttonGroup} />;

    const rawGasFeeData = simulateRes?.fee.amount.toData();
    const formattedGasFee = 
        rawGasFeeData 
        ? beautifyAmountByToken(rawGasFeeData[0].amount, LUNA) + ' LUNA'
        : 'N/A';
    const gasFeeToDisplay = isLoadingEstimatedFee ? (
        <TextSkeleton />
    ) : (
        formattedGasFee
    );
    const gasFeeSection = (
        <Box
            mt={"8px"}
            display="flex"
            justifyContent={"center"}
            alignItems="center"
        >
            <Typography className="label" mr={"8px"}>
                Gas Fee:
            </Typography>
            <Typography>{gasFeeToDisplay}</Typography>
        </Box>
    );

    const formButton = (
        <Box mt="32px">
            {buttonToDisplay}
            {gasFeeSection}
        </Box>
    );

    return (
        <Box>
            {tokenForms()}
            {additionalDetailsContent}
            {formButton}
        </Box>
    );
};
