import classNames from 'classnames';
import { Container } from 'components/container/Container';
import { Text } from 'components/primitives';
import { UIElementProps } from 'components/UIElementProps';
import styles from './FormControl.module.sass';

interface FormControlProps extends UIElementProps {
  label: string;
  labelGap?: boolean;
  helpText?: string;
}

const FormControl = (props: FormControlProps) => {
  const { className, children, label, labelGap = true, helpText } = props;
  return (
    <Container
      className={classNames(className, styles.root)}
      component="section"
      direction="column"
    >
      <Text
        className={classNames(styles.label, {
          [styles.labelGap]: labelGap,
        })}
        variant="heading2"
      >
        {label}
      </Text>
      {helpText && (
        <Text
          className={classNames(styles.label, {
            [styles.label]: labelGap,
          })}
          variant="label"
        >
          {helpText}
        </Text>
      )}
      {children}
    </Container>
  );
};

export { FormControl };
