import { Link, Button } from "@mui/material"

type ClickableIconProps = {
    variant: 'button' | 'externalLink';
    icon: any;
    linkTo?: string;
    onClick?: () => void;
    className?: string;
};

export const ClickableIcon = (props: ClickableIconProps) => {
    const { variant, icon, linkTo, onClick, className } = props;

    if (variant === 'button') {
        return (
            <Button
                variant='text'
                onClick={onClick}
                className={className}
            >
                {icon}
            </Button>
        );
    } else {
        return (
            <Link
                href={linkTo}
                target='_blank'
                className={className}
            >
                {icon}
            </Link>
        );
    }    
};