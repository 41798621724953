import { useState } from "react";
import { useConnectedWallet } from "@terra-money/wallet-provider";
import { Box, Button, Typography } from "@mui/material";
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import { isUndefined } from "lodash";

import { usePoolsQuery } from "queries";
import { Card, Table, SearchBar } from "components";
import { GenerateRewardsListTableColumns, GenerateRewardsListTableRows } from "./helper";
import { VestActionType, VestingModal } from "../VestingModal/VestingModal";

export const RewardsList = () => {
    const [poolSearchInput, setPoolSearchInput] = useState<string>('');
    const [selectedPool, setSelectedPool] = useState<any>({});
    const [openVestingActionModal, setOpenVestingActionModal] =
    useState<boolean>(false);
    const [vestActionType, setVestActionType] =
        useState<VestActionType>("claimOne");
    const [totalRewards, setTotalRewards] = useState<string>('400'); // TODO: Set to total rewards from the chain

    // Check wallet connection
    const connectedWallet = useConnectedWallet();
    const walletAddress = connectedWallet?.walletAddress;
    const walletIsConnected =
    !isUndefined(walletAddress) || !isUndefined(connectedWallet);

    const openModal = (type: VestActionType, selectedPoolData?: any) => {
        setVestActionType(type);
        setOpenVestingActionModal(true);
        setSelectedPool(selectedPoolData);
    };

    const { isLoading: isPoolsLoading, data: pools } =
        usePoolsQuery(connectedWallet);

    const filterGroup = (
        <Box
            display={'flex'}
            alignItems='center'
        >
            <SearchBar 
                value={poolSearchInput}
                onChange={setPoolSearchInput}
                customStyles={{ marginRight: '24px' }}
            />
            <Button
                variant="contained"
                className="button-base button-circle grey"
                onClick={() => {}}
            >
                <FilterListRoundedIcon />
            </Button>
        </Box>
    );

    const noResultsText = walletIsConnected ? 'No results.' : 'Please connect your wallet.';
    const rewardsListTable = (
        <Table
            rows={GenerateRewardsListTableRows(pools ?? [], poolSearchInput)}
            columns={GenerateRewardsListTableColumns(openModal)}
            noResultsText={noResultsText}
        />
    );

    const claimAllButton = walletIsConnected ? (
        <Button
          variant="contained"
          className="button-base button-primary gradient"
          onClick={() => openModal('claimAll')}
        >
          <Typography>Claim All</Typography>
        </Button>
    ) : undefined;

    const vestingActionModal = (
        <VestingModal
            open={openVestingActionModal}
            onClose={() => setOpenVestingActionModal(false)}
            selectedPool={selectedPool}
            type={vestActionType}
            totalRewards={totalRewards}
        />
      );

    return (
        <>
            <Card
                width="100%"
                title="Rewards"
                actionButton={claimAllButton}
            >
                {filterGroup}
                {rewardsListTable}
                {vestingActionModal}
            </Card>
        </>
    );
};