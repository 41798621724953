import { useQuery, UseQueryResult } from 'react-query';
import { useConnectedWallet } from '@terra-money/wallet-provider';
import { QUERY_KEY } from './queryKey';
import { CW20TokensResponse } from 'types';
import { fixTokenResponse } from './utils';

interface CW20TokensNetworkResponse {
  [network: string]: CW20TokensResponse;
}

const fetchCW20Tokens = async (
  network: string
): Promise<CW20TokensResponse> => {
  const response = await fetch('https://assets.terra.money/cw20/tokens.json');

  const tokens: CW20TokensNetworkResponse = await response.json();

  return tokens && tokens[network]
    ? fixTokenResponse('cw20', tokens[network])
    : {};
};

export const useCW20TokensQuery = (
  preferredNetwork?: string
): UseQueryResult<CW20TokensResponse> => {
  const connectedWallet = useConnectedWallet();

  return useQuery(
    [QUERY_KEY.CW20_TOKENS, preferredNetwork ?? connectedWallet?.network.name],
    ({ queryKey }) => {
      if (!queryKey[1]) {
        return undefined;
      }

      return fetchCW20Tokens(queryKey[1]);
    },
    {
      refetchOnMount: false,
    }
  );
};
