import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

interface SearchBarProps {
  value: string;
  onChange: (value: string) => void;
  customStyles?: object;
}

export const SearchBar = (props: SearchBarProps) => {
  const { value, onChange, customStyles } = props;

  return (
    <TextField
      type="search"
      variant="standard"
      placeholder="Search token"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        endAdornment: <SearchIcon />,
        inputProps: { className: "input-search" },
        className: "input-base input-search-container",
        disableUnderline: true,
      }}
      sx={{
        width: "100%",
        ...customStyles,
      }}
    />
  );
};
