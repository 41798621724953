import { Box, Button, Typography } from "@mui/material";
import moment from "moment";

import { generatePoolCompositionCell } from "utils/tableCellDataGenerator";
import {
  Modal,
  WalletGuard,
} from "components";

interface VestingActionModalProps {
  open: boolean;
  onClose: () => void;
  selectedPool: any;
  type: VestActionType;
  totalRewards: string;
}

export type VestActionType = "claimOne" | "claimAll";

export const VestingModal = (props: VestingActionModalProps) => {
  const { open, onClose, selectedPool, type, totalRewards } = props;

  const tokenOptions = selectedPool?.poolComposition;
  const rewards = selectedPool?.rewards;
  const poolName = tokenOptions && generatePoolCompositionCell(tokenOptions);

  // NOTE: Display pool info only when claiming rewards from one pool
  const selectedPoolSection = type === 'claimOne' && (
    <Box mb={'32px'}>
        <Typography className="label" mb={'8px'}>Pool</Typography>
        {poolName}
    </Box>
  );

  const rewardsSection = (
    <Box mb={'32px'}>
        <Typography className="label" mb={'8px'}>Rewards</Typography>
        <Typography>{type === 'claimOne' ? rewards : totalRewards} esDXTR</Typography>
    </Box>
  );

  const claimNowRewards = (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems="center"
      marginY={1}
    >
      <Typography className="label">Claim Now Rewards</Typography>
      <Typography>50 esDXTR</Typography>
    </Box>
  );

  const vestGraduallyRewards = (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems="center"
      marginY={1}
    >
      <Typography className="label">Vest Rewards</Typography>
      <Typography>100 esDXTR</Typography>
    </Box>
  );

  const vestExpiresDate = moment(new Date())
    .add(28, "days") // 4 weeks
    .format("D MMM YYYY");
  const unlockDateSection = (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems="center"
      marginY={1}
    >
      <Typography className="label">Vest Expires</Typography>
      <Typography>{vestExpiresDate}</Typography>
    </Box>
  );

  const additionalDetailsContent = (
    <Box>
      {vestGraduallyRewards}
      {unlockDateSection}
      {claimNowRewards}
    </Box>
  );

  const vestingInfoPanel = (
    <Box className="info-panel-red" mt={"32px"}>
      <Typography mb={"8px"}>What is this about?</Typography>
      <Typography variant="body3">
        Vesting over 4 weeks converts esDXTR to DXTR in 4 weeks time, penalty
        free. <br />
        Claiming now incurs 50% fee.
      </Typography>
    </Box>
  );

  const vestGraduallyButton = (
    <Button
      variant="contained"
      className="button-base button-primary gradient"
      fullWidth
      onClick={() => {}}
    >
      <Typography variant="button">Vest Over 4 Weeks</Typography>
    </Button>
  );

  const claimNowButton = (
    <Button
      variant="contained"
      className="button-base button-primary blue"
      fullWidth
      onClick={() => {}}
      sx={{ marginTop: "16px" }}
    >
      <Typography variant="button">Claim Now</Typography>
    </Button>
  );

  const buttonGroup = (
    <Box
      display={'flex'}
      flexDirection='column'
    >
      {vestGraduallyButton}
      {claimNowButton}
    </Box>
  );

  const buttonToDisplay = <WalletGuard element={buttonGroup} />

  const formButton = <Box mt="32px">{buttonToDisplay}</Box>;

  return (
    <Modal open={open} onClose={() => onClose()} title={'Claim Rewards'}>
      {selectedPoolSection}
      {rewardsSection}
      {additionalDetailsContent}
      {vestingInfoPanel}
      {formButton}
    </Modal>
  );
};
