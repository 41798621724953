import { InputAdornment } from '@mui/material';
import classNames from 'classnames';
import { TextInput, TextInputProps } from 'components/primitives/text-input';
import { FormControl } from 'components/form-control/FormControl';
import { ReactComponent as AddressbookIcon } from 'components/assets/Addressbook.svg';
import { ReactComponent as CheckIcon } from 'components/assets/Check.svg';
import { Token } from 'types';
import { useMemo } from 'react';
import styles from './WalletInput.module.sass';

interface BaseProps extends Pick<TextInputProps, 'value'> {
  className?: string;
  label: string;
  error?: string;
  valid?: boolean;
  selectedToken?: Token;
  addressBookVisible?: boolean;
  onChange: (textOrAddressBookEntry: string) => void;
}

interface WalletInputProps1 extends BaseProps {
  addressBookEnabled: false;
  onChange: (textOrAddressBookEntry: string) => void;
}

interface WalletInputProps2 extends BaseProps {
  addressBookEnabled: true;
  onChange: (textOrAddressBookEntry: string) => void;
}

type WalletInputProps = WalletInputProps1 | WalletInputProps2;

const WalletInput = (props: WalletInputProps) => {
  const {
    className,
    label,
    error,
    valid,
    value,
    onChange,
    addressBookEnabled,
    addressBookVisible,
    selectedToken,
  } = props;

  return (
    <FormControl className={classNames(className, styles.root)} label={label}>
      <TextInput
        placeholder="Type a wallet address"
        error={error !== undefined}
        helperText={error}
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        InputProps={{
          endAdornment: (
            <>
              {valid && (
                <InputAdornment position="end">
                  <CheckIcon className={styles.check} />
                </InputAdornment>
              )}
              {addressBookEnabled && addressBookVisible && (
                <InputAdornment position="end">
                  <AddressbookIcon
                    className={styles.address_book}
                    onClick={() => {}}
                  />
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </FormControl>
  );
};

export { WalletInput };
