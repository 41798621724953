import { useCallback, useMemo } from 'react';
import { ConnectType, useWallet } from '@terra-money/wallet-provider';
import { Box, Button, Typography } from '@mui/material';
import { ReactComponent as WalletIcon } from 'components/assets/Wallet.svg';
import { Modal } from 'components';

const connectionsMetadata = {
    [ConnectType.EXTENSION]: {
      icon: <WalletIcon />,
    },
    [ConnectType.WALLETCONNECT]: {
      icon: <WalletIcon />,
    },
};

const supportedSet = new Set(
    Object.keys(connectionsMetadata)
) as Set<ConnectType>;

interface ConnectWalletModalProps {
    open: boolean;
    onClose: () => void;
};

export const ConnectWalletModal = (props: ConnectWalletModalProps) => {
    const { open, onClose } = props;

    const { connect, availableConnections } = useWallet();

    const connectWallet = useCallback(
        (connectionType: ConnectType) => {
          connect(connectionType);
          onClose();
        },
        [connect, onClose]
    );
    
    const supportedConnections = useMemo(
        () => availableConnections.filter((c) => supportedSet.has(c.type)),
        [availableConnections]
    );

    const mappedWalletsAvailable = supportedConnections.map((wallet, id) => {
        return (
            <Button
                variant='text'
                key={id}
                onClick={() => connectWallet(wallet.type)}
                className='button-base button-wallet-options'
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                >
                    <img
                        src={wallet.icon}
                        alt={wallet.name}
                        className='icon-wallet'
                    />
                    <Typography variant='body1' ml={'16px'}>{wallet.name}</Typography>
                </Box>
            </Button>
        );
    });

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={'Connect to a wallet'}
        >
            <Box display={'flex'} flexDirection='column'>
                {mappedWalletsAvailable}
            </Box>
        </Modal>
    );
};