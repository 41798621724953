import { useForm } from "hooks";
import { NetworkInfo, useWallet } from "@terra-money/wallet-provider";

export const validateAddress = (input: string) => {
  var regex =
    /(terra([a-z0-9]{39}|[a-z0-9]{59})\b)|(terravaloper[a-z0-9]{39}\b)|([a-z0-9-]+\.ust\b)/;
  if (regex.test(input)) {
    return undefined;
  } else {
    return "Invalid address input.";
  }
};

interface ReadOnlyWalletInput {
  walletAddr: string;
  chainId: string;
}

interface ReadOnlyWalletState extends ReadOnlyWalletInput {
  walletAddrValid?: boolean;
  walletAddrError?: string;
  submitDisabled: boolean;
}

const initialState: ReadOnlyWalletState = {
  walletAddr: "",
  chainId: "",
  submitDisabled: true,
};

export const useReadOnlyWalletForm = (networks: NetworkInfo[]) => {
  const { network } = useWallet();

  return useForm<ReadOnlyWalletInput, ReadOnlyWalletState>(
    async (input, getState, dispatch) => {
      const state = {
        ...getState(),
        ...input,
      };

      const walletAddrError =
        (state.walletAddr && validateAddress(state.walletAddr)) || undefined;

      const walletAddrValid =
        state.walletAddr.length > 0 && walletAddrError === undefined;

      const submitDisabled = Boolean(
        !Boolean(state.chainId) ||
          state.walletAddr === undefined ||
          state.walletAddr.length < 1 ||
          walletAddrError
      );

      dispatch({
        ...state,
        walletAddrError,
        walletAddrValid,
        submitDisabled,
      });
    },
    {
      ...initialState,
      chainId: network.chainID ?? networks[1].chainID,
    }
  );
};
