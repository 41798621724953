import { Box, Typography, Button } from "@mui/material";
import { GridColumns } from '@mui/x-data-grid';

import { generatePoolCompositionCell } from "utils/tableCellDataGenerator";
import { Token } from "types";
import { Pool } from "queries";
import { useSearchPool } from "hooks";
import { VestActionType } from "../VestingModal/VestingModal";

export const GenerateRewardsListTableColumns = (
    openModal: (type: VestActionType, selectedPoolData: any) => void
) => {
    const generatePositionCell = (position: string[]) => {
        const tokensList = position && position.map((token: string, index: number) => {
            const tokenName = (
                <Typography variant="body2" key={index}>
                    {token}
                </Typography>
            );
            return tokenName;
        });

        const tokenBreakdown = (
            <Box
                display={'flex'}
                flexDirection='column'
                alignItems={'flex-start'}
            >
                {tokensList}
            </Box>
        );

        return tokenBreakdown;
    };

    const mappedColumns: GridColumns = [
        {
            field: 'poolComposition',
            headerName: 'Pool',
            flex: 1.5,
            sortable: false,
            renderCell: (params: any) => generatePoolCompositionCell(params.value)
        },
        {
            field: 'position',
            headerName: 'Your Position',
            flex: 1,
            sortable: false,
            renderCell: (params: any) => generatePositionCell(params.value)
        },
        {
            field: 'rewards',
            type: 'number',
            headerName: 'Rewards',
            flex: 1,
            renderCell: (params: any) => <Typography variant="body2">{params.value} esDXTR</Typography>
        },
        {
            field: 'actions',
            type: 'actions',
            flex: 1,
            getActions: (params) => {
                return [(
                    <Button
                        variant="contained"
                        className="button-base button-primary blue"
                        onClick={() => openModal('claimOne', params.row)}
                    >
                        <Typography>Claim</Typography>
                    </Button>
                )];
            },
        }
    ];

    return mappedColumns;
};

export const GenerateRewardsListTableRows = (data: Pool[], searchInput?: string) => {
    const generatePoolName = (tokens: any) => {
        const tokensList = tokens.map((token: Token) => token.symbol);
        const poolName = tokensList.join(' - ');

        return poolName;
    };
    
    const mappedRows = data.map((pool) => {
        return {
            id: pool.pool_address,
            poolComposition: {
                tokens: pool.tokens,
                name: generatePoolName(pool.tokens),
            },
            position: ['20 LUNA', '25 TEST'], // Mock data
            rewards: 200 // Mock data
        };
    });

    // Filter the rows based on search input
    const filteredRows = useSearchPool(mappedRows, searchInput);

    return filteredRows || [];
}
