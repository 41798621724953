import { Box, Typography } from "@mui/material";
import { Stats } from './Stats/Stats';
import { PoolsList } from './PoolsList/PoolsList';

export const Pools = () => {
    return (
        <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={"center"}
            width='100%'
        >
            <Typography variant="h1" mb={"48px"}>
                Pools
            </Typography>
            <Stats />
            <PoolsList />
        </Box>
    );
};