import { BaseSyntheticEvent } from "react";
import {
    Box,
    TextField,
    Typography,
    Chip
} from "@mui/material";
import { isEmpty } from "lodash";

import { floatRegex } from "variables";
import { Modal } from "components";

interface SlippageConfigModalProps {
    open: boolean;
    onClose: () => void;
    slippageValue: string;
    setSlippage: (slippage: string) => void;
};

export const SlippageConfigModal = (props: SlippageConfigModalProps) => {
    const { open, onClose, slippageValue, setSlippage } = props;

    const onCloseSlippageConfigModal = () => {
        // If slippage is not inputted, set it back to default value
        if (isEmpty(slippageValue)) {
            setSlippage("0.1");
        }
    
        onClose();
    };

    const onChangeCustomSlippage = (event: BaseSyntheticEvent) => {
        const slippageValue = event.target.value;
        const validSlippageRange =
            parseFloat(slippageValue) >= 0 && parseFloat(slippageValue) <= 10;

        if (
            isEmpty(slippageValue) ||
            (floatRegex.test(slippageValue) && validSlippageRange)
        ) {
            setSlippage(slippageValue);
        }
    };

    return (
        <Modal
            open={open}
            onClose={onCloseSlippageConfigModal}
            title="Slippage Settings"
            >
                <Typography className="label">Slippage Tolerance</Typography>
                <Box display={"flex"} alignItems="center" mt={'8px'}>
                    <Chip
                        label="0.1%"
                        data-value={"0.1"}
                        onClick={() => setSlippage("0.1")}
                        sx={{ marginRight: '8px' }}
                        className='button-chip'
                    />
                    <Chip
                        label="0.5%"
                        data-value={"0.5"}
                        onClick={() => setSlippage("0.5")}
                        sx={{ marginRight: '8px' }}
                        className='button-chip'
                    />
                    <Chip
                        label="1%"
                        data-value={"1"}
                        onClick={() => setSlippage("1")}
                        sx={{ marginRight: '8px' }}
                        className='button-chip'
                    />
                    <TextField
                        type={"text"}
                        variant="outlined"
                        placeholder={slippageValue ?? "0.1"}
                        value={slippageValue}
                        onChange={(event) => onChangeCustomSlippage(event)}
                        InputProps={{
                            endAdornment: <Typography>%</Typography>,
                            inputProps: { className: 'input-slippage' },
                            className: 'input-base input-slippage-container'
                        }}
                    />
                </Box>
            </Modal>
    );
};