import { ConnectedWallet } from "@terra-money/wallet-provider";
import { useQuery } from "react-query";
import { DEXTER_FACTORY, getContractAddress } from "utils/getContractAddress";
import { QUERY_KEY } from "./queryKey";
import { contractQuery } from "./utils";
import { TokenWithAmount } from "types";
import { Token } from "types/types";
import { fetchTokenBalance } from "./useBalanceQuery";

export type Pool = {
    pool_address: string;
    pool_token: string;
    pool_token_amount: string;
    tvl: string;
    total_volume: string;
    apr: string;
    symbol: string;
    tokens: Token[];
    amounts: string[];
    type: string;
    gauge_address?: string;
    gauge_votes?: string;
    bribes?: any[];
    fees?: any[];
};

export const usePoolsQuery = (wallet?: ConnectedWallet) => {
    return useQuery(
        [QUERY_KEY.POOLS, wallet],
        async (): Promise<Pool[]> => {
            if (!wallet) {
                console.log("wallet not found");
                return [];
            }

            let res = await fetch(
                "https://api-test.dexter.money/api/pools"
            ).then((r) => r.json());

            let pools = [];
            for (let pair of res.pools) {
                let lpBalance;
                if (wallet) {
                    lpBalance = await fetchTokenBalance({
                        lcdUrl: wallet.network.lcd,
                        chainId: wallet.network.chainID,
                        walletAddr: wallet.walletAddress,
                        tokenAddr: pair.pool_token_address,
                    });
                }
                pools.push({
                    pool_address: pair.pool_address,
                    pool_token: pair.pool_token_address,
                    pool_token_amount: lpBalance ?? "0",
                    gauge_address: pair.gauge.address,
                    bribes: pair.gauge.bribes,
                    fees: pair.gauge.fees,
                    gauge_votes: pair.gauge.votes,
                    tokens: [pair.token0, pair.token1],
                    amounts: [pair.token0_amount, pair.token1_amount],
                    total_volume: pair.total_volume,
                    apr: pair.apr,
                    tvl: pair.tvl,
                    type: pair.pair_type,
                } as Pool);
            }
            return pools;
        },
        {
            initialData: [],
        }
    );
};
