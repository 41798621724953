import { Box, Typography } from "@mui/material";
import { SwapForm } from "./SwapForm/SwapForm";

export const Swap = () => {
    return (
        <Box
            display={"flex"}
            flexDirection="column"
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={"center"}
            width='100%'
        >
            <Typography variant="h1" mb={"48px"}>
                Swap
            </Typography>
            <SwapForm />
        </Box>
    );
};